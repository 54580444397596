import * as types from 'actions/actionTypes';

const initial = {
  user: {},
  pathname: null,
  status: 'NOT_LOGGED_IN'
};

export default (state = initial , action) => {
  switch (action.type) {
    case types.FETCH_LOGIN_STATE_ERROR:
      return {...initial, pathname: action.payload.pathname};
    case types.LOGIN:
      return {...state, status: 'DOING_LOGIN', error: undefined};
    case types.LOGIN_ERROR:
      return {...state, status: 'NOT_LOGGED_IN', error: action.payload};
    case types.LOGIN_SUCCESS:
      return {...state,
        status: 'LOGGED_IN',
        isLoggedIn: true,
        user: action.payload.user || state.user,
        error: undefined,
        jwt: action.payload.jwt,
        pathname: action.payload.pathname};
    case types.LOGOUT:
      return {...initial, pathname: '/'};
    case types.SIGNUP:
      return {...state, status: 'DOING_SIGNUP', error: undefined};
    case types.SIGNUP_SUCCESS:
      return {...state, status: 'SIGNED_UP', error: undefined};
    case types.SIGNUP_ERROR:
      return {...state, status: 'NOT_LOGGED_IN', error: action.payload};
    case types.CONFIRM_USER:
      return {...state, status: 'DOING_CONFIRM_USER', error: undefined};
    case types.CONFIRM_USER_SUCCESS:
      return {...state, status: 'USER_CONFIRMED', error: undefined};
    case types.CONFIRM_USER_ERROR:
      return {...state, status: 'NOT_LOGGED_IN', error: action.payload};
    case types.INVITE_USER:
      return {...state, status: 'DOING_INVITE_USER', error: undefined};
    case types.INVITE_USER_ERROR:
      return {...state, status: 'NOT_LOGGED_IN', error: action.payload};

    default:
      return state;
  }
};
