import superFetch from 'modules/superFetch';

export const getTransactions = superFetch({
  url: '/api/data/transactions.json',
  method: "POST"
});

export const fetchJob = superFetch({
  url: reqId => ('/api/jobs/job?reqId=' + reqId)
});

export const getReplay = superFetch({
  url: '/api/data/replay.json',
  method: "POST"
});
