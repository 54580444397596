import * as types from './actionTypes';
import {createAction} from 'redux-actions';

export const seriesAdd = createAction(types.SERIES_ADD);
export const seriesAddSuccess = createAction(types.SERIES_ADD_SUCCESS);
export const seriesAddError = createAction(types.SERIES_ADD_ERROR);

export const seriesApplyCode = createAction(types.SERIES_APPLY_CODE);


export const seriesDelete = createAction(types.SERIES_DELETE);
export const seriesSelect = createAction(types.SERIES_SELECT);
export const seriesToogle = createAction(types.SERIES_TOOGLE);
