import { takeEvery, call} from 'redux-saga/effects';
import ReactGA from 'react-ga';
import * as types from 'actions/actionTypes';

const snap = navigator.userAgent === 'ReactSnap';
const ga = process.env.REACT_APP_STAGE === 'prod' && !snap ? ReactGA : undefined;

if (ga) {
    ga.initialize('UA-114518873-1');
}

const sendEvent = async (category, action, label) => {
    if (ga) {
        return ga.event({
            category: category,
            action: action,
            label: label
        });
    }
};

function* backtestErrorEvent(action) {
    yield call(sendEvent, 'Backtest', 'Error', action.payload?.id);
}

function* backtestSuccessEvent(action) {
    yield call(sendEvent, 'Backtest', 'Success', action.payload?.id);
}


export function* handleGaEvents() {
    yield takeEvery(types.PORT_BACKTEST_ERROR, backtestErrorEvent);
    yield takeEvery(types.PORT_BACKTEST_SUCCESS, backtestSuccessEvent);
}

