import * as types from 'actions/actionTypes';

// reducer for backtest result data
const backtestData = (state = {}, action) => {

  switch (action.type) {

    case types.PORT_BACKTEST_SUCCESS:
      let pl = action.payload;
      let newData = {id: pl.id, name: pl.name, currency: pl.currency, size: pl.size};
      // extract value and rebalance data
      if (pl.data && pl.data.returns && pl.data.returns.columns) {
        newData.data = pl.data.returns.data.map(r => [r[0]*1000, r[2]]);
        newData.components = {
          columns: pl.data.components.columns,
          data: pl.data.components.data.map(r => [r[0]*1000, ...r.slice(1)])
        };
        newData.flags = {
          id: "rebalance",
          name: "rebalance",
          data: pl.data.returns.data.reduce((acc, r) => {
            if (acc.length === 0 || r[1] !== acc[acc.length-1][1]) {
              acc.push([r[0]*1000, r[1]]);
            }
            return acc;
          }, [])
        };
      }
      // extract positions
      let positions = [];
      if (pl.data && pl.data.positions) {
        positions = pl.data.positions.reduce((a, s) => [
          ...a,
          ...s.positions.columns.slice(1).map((e, j)=>({
            id: s.contract + "__" + e,
            name: s.contract + ": " + e,
            data: s.positions.data.map(r => [r[0] * 1000, r[j + 1]])
          }))
        ], []);
      }
      if (pl.data && pl.data.last_positions) {
          newData.positions = pl.data.last_positions;
      }
      newData.subSeries = positions;
      return {...state, [action.payload.id]:newData};

    case types.LOGOUT:
      return {};

    default:
      return state;
  }
}

export default backtestData;
