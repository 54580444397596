import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import * as types from 'actions/actionTypes';
import {getTransactions, getReplay} from 'api/transactions';
import {getPositions} from 'api/futures';
import {asyncFetch} from 'sagas/asyncFetch';
import {fetchTransactionsSuccess, fetchTransactionsError,
  fetchReplaySuccess, fetchReplayError,
  fetchPositionsSuccess, fetchPositionsError
} from 'actions/runActions';

import {getTransactData} from '../selectors/runSelectors';

export function* handleFetchTransactions() {
  while (true) {
    yield take(types.FETCH_TRANSACTIONS);
    let transact = yield select(getTransactData);

    const { payload, err } = yield call(asyncFetch, getTransactions, {...transact});

    if (payload && !err) {
      yield put(fetchTransactionsSuccess(payload));
    } else {
      yield put(fetchTransactionsError(err));
    }
  }
}

export function* handleFetchReplay() {
  while (true) {
    yield take(types.FETCH_REPLAY);
    let transact = yield select(getTransactData);

    const { payload, err } = yield call(asyncFetch, getReplay, {...transact});

    if (payload && !err) {
      yield put(fetchReplaySuccess(payload));
    } else {
      yield put(fetchReplayError(err));
    }
  }
}


function* doFetchPositions(action) {

  if (action.payload) {
    const {payload, err} = yield call(asyncFetch, getPositions, action.payload, {silent: true});
    if (payload && !err) {
      yield put(fetchPositionsSuccess(payload));
    } else {
      yield put(fetchPositionsError(err));
    }
  } else {
    yield put(fetchPositionsError(null));
  }
  
}

export function* handleFetchPositions() {
  yield takeLatest(types.FETCH_POSITIONS, doFetchPositions);
}
