

export const benchmarkPort = id => ({
    name: 'Stocks/Bonds Inv. Vol.',
    id,
    assetsClasses: {
        columns: ['assetClass', 'classWeight'],
        data: [['Equity', 0.6],
            ['Bonds', 1.4]]
    },
    assets: {
        columns: ['ticker', 'genericCode', 'multiplier', 'rollContract', 'assetWeight'],
        data: [['ES', 'CME/SP', 50, 'max_oi', 1],
            ['ZN', 'CME/TY', 1000, 'max_oi', 1]]
    },
    strats: {
        columns: ['assetClass', 'strat', 'stratParams', 'stratWeight'],
        data: [['Equity', 'long', null, 1],
            ['Bonds', 'long', null, 1]]
    },
    weighting: {
        volLookback: 200,
        correlLookback: 200,
        methods: [{type: '1/V', weight: 1, targetVol: 0.1}] 
    }
});

export const emptyPort = {
    name: 'New portfolio',
    assetsClasses: {
        columns: ['assetClass', 'classWeight'],
        data: []
    },
    assets: {
        columns: ['ticker', 'genericCode', 'multiplier', 'rollContract', 'assetWeight'],
        data: []
    },
    strats: {
        columns: ['assetClass', 'strat', 'stratParams', 'stratWeight'],
        data: []
    }
};
