
export const retry = (fun, maxRetry=0, retryDelay=1000) => (...args) => (new Promise((resolve, reject) => {
  var i = 0;
  function doRetry() {
    i++;
    fun(...args)
    .then(resolve)
    .catch(err => {
      if (i > maxRetry) {
        reject(err);
      } else {
        setTimeout(doRetry, retryDelay);
      }
    });
  }
  doRetry();
}));
