import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as types from 'actions/actionTypes';
import {startBacktest} from 'api/futures';
import {fetchPublicPortfolio, postBacktestError} from 'api/state';
import {portBacktestSuccess, portBacktestError, portAddPublicError, 
    portAddPublicSuccess, portSave} from 'actions/portfoliosActions';
import {getSelectedPort} from '../selectors/portfolios';
import {asyncFetch} from 'sagas/asyncFetch';


function* doBacktest(action) {
    let port = yield select(getSelectedPort);

    const { payload, err } = yield call(asyncFetch, startBacktest, port);

    if (payload && !err) {
        if (port.readonly && payload.portfolio) {
            payload.portfolio.readonly = true;
        }
        yield put(portBacktestSuccess({
            data: payload, 
            id: port.id, 
            name: port.name
        }));
    } else {
        yield put(portBacktestError(action.payload));
        yield call(postBacktestError, {data: port});
    }
}

export function* handleBacktest() {
    yield takeLatest(types.PORT_BACKTEST, doBacktest);
}

function* fetchPortfolio(action) {
    const {payload, err} = yield call(fetchPublicPortfolio, action.payload);
    if (err) {
        yield put(portAddPublicError());
    } else {
        yield put(portAddPublicSuccess(payload));
        yield put(portSave({
            id: payload.id,
            name: payload.name,
            data: {portfolio: {id: payload.id, readonly: true, public: false}}
        }));
    }
}

export function* handleFetchPortfolio() {
    yield takeLatest(types.PORT_ADD_PUBLIC, fetchPortfolio);
}
