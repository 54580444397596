import React, { Component } from 'react';
import {
    Container, Col, Row
} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {confirmUser} from 'actions/authActions';
import queryString from 'query-string';

import Loading from 'components/Loading';

import './Login.css';

const snap = navigator.userAgent === 'ReactSnap';

class ConfirmUser extends Component {

    constructor(props) {
        super(props);
        this.state = {error: false, email:null};
    }

    componentDidMount(){
        let {email, key} = queryString.parse(this.props.location.search);
        if (email && key) {
            this.props.dispatch(confirmUser({username: email, key}));
            this.setState({email});
        } else {
            this.setState({error: true});
        }
    }

    render() {
        const { auth } = this.props;

        if (!snap && (this.state.error || auth.error || auth.status === 'USER_CONFIRMED')) {
            return (
                <div className="Login">
                    <Container  className="lander">
                        <Row>
                            {(auth.status === 'USER_CONFIRMED') &&
                <Col md={12}>
                    <h2 style={{marginBottom:'1em', width:'100%', display:'block', textAlign:'center'}}>Welcome!</h2>
                    <p>Your email address has been validated. You can now <Link to={`/login/?email=${this.state.email}`}>log in</Link>.</p>
                </Col>}
                            {(this.state.error || auth.error) &&
                <Col md={12}>
                    <h2 style={{marginBottom:'1em', width:'100%', display:'block', textAlign:'center'}}>Oops...</h2>
                    <p>An error occured. Your validation code has expired or your email adress is already validated.</p>
                </Col>}
                        </Row>
                    </Container>
                </div>
            );
        }

        return (<Loading />);

    }
}

function mapStateToProps({ auth }) {
    return { auth };
}

export default connect(mapStateToProps)(ConfirmUser);
