import * as types from 'actions/actionTypes';

// reducer for series data
export const seriesData = (state = {}, action) => {
    let pl = action.payload;
    switch (action.type) {
    case types.SERIES_ADD_SUCCESS:
        let newData = {};
        if (pl.series && pl.series.columns) {
            newData.id = pl.id;
            newData.version = pl.version;
            newData.name = pl.name + (pl.params.strat !== 'long' ? ' - ' + pl.params.strat.charAt(0).toUpperCase() + pl.params.strat.slice(1) : '');
            if (pl.disambig || pl.disambig === 0) {
                newData.disambig = pl.disambig;
            } else {
                newData.disambig = Object.values(state).reduce((a, e) => e.name === newData.name && e.id !== newData.id ? 
                    Math.max(a, e.disambig + 1) : a, 0);
            }
            newData.data = pl.series.data.map(r => [r[0]*1000, r[2]]);
            newData.subSeries = [
                {
                    id: 'pos',
                    name: 'Position',
                    data: pl.series.data.map(r => [r[0]*1000, r[3]])
                },
                {
                    id: 'daysToExp',
                    name: 'Days to Expiry',
                    data: pl.series.data.map(r => [r[0]*1000, r[4]])
                },
                {
                    id: 'oi',
                    name: 'Open Interest',
                    data: pl.series.data.map(r => [r[0]*1000, r[5]])
                }
            ];
            newData.flags = {
                id: 'rollPoints',
                name: 'Roll days',
                data: pl.series.data.reduce((acc, r) => {
                    if (acc.length === 0 || r[1] !== acc[acc.length-1].text) {
                        acc.push({x: r[0]*1000, title:r[1].substr(r[1].length-5,1)+r[1].substr(r[1].length-2,2), text:r[1]});
                    }
                    return acc;
                }, [])
            };
            let extras = pl.series.columns.slice(6);
            for (let i in extras) {
                newData.subSeries.push({
                    id: extras[i],
                    name: extras[i].charAt(0).toUpperCase() + extras[i].slice(1),
                    data: pl.series.data.map(r => [r[0]*1000, parseFloat(r[parseInt(i)+6])])
                });
            }
            return {...state, [pl.id]: newData};
        }
        break;

    case types.SERIES_DELETE:
        let {[action.payload+'']:del, ...newState} = state;
        return newState;

    case types.LOGOUT:
        return {};

    default:
        return state;
    }
};

export const ratesData  = (state = {}, action) => {
    switch (action.type) {
    case types.RATES_SELECT:
        return action.payload.reduce((a,e) => ({
            ...a,
            [e.id]: state[e.id] || e
        }), {});
    case types.RATES_FETCH_SUCCESS:
        return action.payload.reduce((a,e) => {
            a[e.code].data = e.data.data.map(r=>[r[0]*1000, r[1]]);
            return a;
        }, {...state});
    case types.LOGOUT:
        return {};
    default:
        return state;
    }
};
