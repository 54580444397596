import React from 'react';
import { hydrate, render } from 'react-dom';
import {Provider} from 'react-redux';
import configureStore from './store/configureStore';

import './index.css';
import App from './App';
import {unregister} from './registerServiceWorker';

const store = configureStore();

const app =  (<Provider store={store}>
    <App />
</Provider>);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    hydrate(app, rootElement);
} else {
    render(app, rootElement);
}

unregister();