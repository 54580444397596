import React, { Component } from 'react';
import {
  Form,
  Container, Col, Row} from 'react-bootstrap';
import AsyncButton from 'components/AsyncButton';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import queryString from 'query-string';

import {clickLogout} from 'actions/authActions';
import {cognitoResetPassword, cognitoNewPassword} from 'api/auth';

import './Login.css';

const initState = {
  email: '',
  password: '',
  passwordVerif: '',
  code: '',
  step: 'INPUT_EMAIL',
  working: false
};

class ResetPassword extends Component {

  state = {...initState};

  componentDidMount() {
    let {email} = queryString.parse(this.props.location.search);
    if (email) {
      this.setState({email});
    }
  }

  handleReset(e) {
    e.preventDefault();
    const { email } = this.state;
    this.props.dispatch(clickLogout());
    this.setState({working: true});
    cognitoResetPassword(email)
      .finally(() => this.setState({step: 'INPUT_PWD', working: false}));
  }

  handleNewPasswd(e) {
    e.preventDefault();
    const { email, password, code } = this.state;
    this.setState({working: true});
    cognitoNewPassword(email, password, code)
      .then(() => this.setState({step: 'SUCCESS', working: false}))
      .catch(() => this.setState({step: 'ERROR', working: false}));
  }

  handleChange(column, value) {
    this.setState({[column]: value});
  }

  validator1() {
    var re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!this.state.email) {
      return {disabled: true, message: "Email is missing"};
    } else if(!re.test(this.state.email)) {
      return {disabled: true, message: "Email is invalid"};
    } else {
      return {disabled: false, message: null};
    }
  }

  validator2() {
    if (!this.state.password || !this.state.passwordVerif || !this.state.code) {
      return {disabled: true, message: "Password or verification code is missing"};
    } else if(this.state.password !== this.state.passwordVerif) {
      return {disabled: true, message: "Passwords don't match"};
    } else if(this.state.password.length<6) {
      return {disabled: true, message: "Password must be at least 6 characters long"};
    } else {
      return {disabled: false, message: null};
    }
  }

  render() {
    const { step, email, password, passwordVerif, code } = this.state;

    let content, disabled, message;

    switch(step) {
      case 'INPUT_EMAIL':
        ({disabled, message} = this.validator1());
        content = <div>
          <p style={{marginBottom: '1em'}}>Enter your email address below and we'll send you a verification code.</p>
          <form onSubmit={this.handleReset.bind(this)}>
            <Form.Group controlId="email">
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => this.handleChange('email', e.target.value)} />
              </Form.Group>
              <Form.Group>
                <AsyncButton
                  type="submit"
                  variant="primary"
                  working={this.state.working}
                  block
                  disabled={disabled}
                  disabledMessage={message}
                  workingText="Wait...">Reset password</AsyncButton>
              </Form.Group>
            </form>
            <p style={{textAlign:"right", marginBottom:0}}>Don't have an account yet? <Link to="/signup/">Sign up</Link></p>
        </div>;
        break;
      case 'INPUT_PWD':
        ({disabled, message} = this.validator2());
        content = <div>
          <p>If your email matches our records you'll receive shortly a verification code.</p>
          <p>Please enter your verification code and your new password.</p>
          <form onSubmit={this.handleNewPasswd.bind(this)}>
            <Form.Group controlId="code">
              <Form.Control
                type="text"
                placeholder="Verification code"
                value={code}
                onChange={(e) => this.handleChange('code', e.target.value)} />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Control
                type="password"
                placeholder="New password"
                value={password}
                onChange={(e) => this.handleChange('password', e.target.value)} />
            </Form.Group>
            <Form.Group controlId="passwordVerif">
              <Form.Control
                type="password"
                placeholder="Confirm password"
                value={passwordVerif}
                onChange={(e) => this.handleChange('passwordVerif', e.target.value)} />
            </Form.Group>
            <Form.Group>
              <AsyncButton
                type="submit"
                variant="primary"
                working={this.state.working}
                block
                disabled={disabled}
                disabledMessage={message}
                workingText="Wait...">Change password</AsyncButton>
            </Form.Group>
          </form>
          <p style={{textAlign:"right", marginBottom:0}}>Don't have an account yet? <Link to="/signup/">Sign up</Link></p>
        </div>;
        break;
      case 'SUCCESS':
        content = <div>
          <p>Your password has been changed!</p>
          <p>You can now <Link to={"/login/?email=" + this.state.email}>log in</Link> back to your account</p>
        </div>;
        break;
      case 'ERROR':
        content = <div>
          <p>The verification code you supplied is invalid and we cannot change your password.</p>
          <p>If you believe the error is on our side please <Link to="/contact/">contact us</Link>.</p>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <p style={{textAlign:'left'}}><a style={{cursor: 'pointer'}} onClick={()=>this.setState({...initState})}>Go back</a></p>
        </div>;
        break;
      default:
        content = {};
    }

    return (
      <div className="Login">
        <Container  className="lander">
          <Row>
            <Col md={12}>
              <h2 style={{marginBottom:"1em", width:"100%", display:"block", textAlign:"center"}}>Reset your password</h2>
              {content}
              </Col>
              </Row>
            </Container>
          </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    doingResetPassword: state.lifecycle.doingResetPassword
  };
}

export default connect(mapStateToProps)(ResetPassword);
