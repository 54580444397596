import { fork } from 'redux-saga/effects';
import {handleFetchLoginState, handleLogin, handleSignup, handleLogout, handleConfirmUser} from 'views/login/sagas/authSaga';
import * as series from 'views/strategies/sagas/seriesSaga';
import * as rates from 'views/strategies/sagas/ratesSaga';
import * as params from './paramsSaga';
import * as backtest from 'views/backtest/sagas/backtestSaga';
import * as saveState from './saveState';
import {handleGaEvents} from './gaEvents';
import * as run from 'views/run/sagas/runSagas';
import * as contact from 'sagas/contactSaga';

export default function* rootSaga() {
    yield fork(handleFetchLoginState);
    yield fork(handleLogin);
    yield fork(handleSignup);
    yield fork(handleLogout);
    yield fork(handleConfirmUser);
    yield fork(series.handleSeriesAdd);
    yield fork(series.handleSeriesApplyCode);
    yield fork(params.handleParamsLoad);
    yield fork(backtest.handleBacktest);
    yield fork(backtest.handleFetchPortfolio);
    yield fork(saveState.handleSaveState);
    yield fork(handleGaEvents);
    yield fork(saveState.handleFetchState);
    yield fork(rates.handleRatesFetch);
    yield fork(run.handleFetchTransactions);
    yield fork(run.handleFetchReplay);
    yield fork(run.handleFetchPositions);
    yield fork(contact.handleContactSend);
    yield fork(contact.handleContactSubscribe);
}
