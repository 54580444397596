export const assetsColsState = ['ticker', 'genericCode', 'rollContract', 'assetWeight', 'multiplier'];
export const assetsColsTable = ['contractName', 'assetClass', 'ticker', 'rollContract', 'assetWeight', 'weight',
    'contracts', 'size', 'lastPrice', 'currency', 'multiplier', 'genericCode', 'rollContract_selValues'];

export const stratsAssetsColsState = ['ticker', 'rollContract', 'strat', 'stratParams', 'stratWeight'];
export const stratsAssetsColsTable = ['assetClass', 'ticker', 'rollContract', 'strat', 'stratParams',
    'stratWeight', 'weight', 'strats_selValues'];

export const stratsClassesColsState = ['assetClass', 'strat', 'stratParams', 'stratWeight'];
export const stratsClassesColsTable = ['assetClass', 'strat', 'stratParams',
    'stratWeight', 'strats_selValues'];

export const classesColsState = ['assetClass', 'classWeight'];
export const classesColsTable = ['assetClass', 'classWeight', 'nbContracts'];

export const specsColsTable = ['contractName', 'type', 'description'];

export const transacFuturesCols = ['date', 'ticker', 'expiry', 'quantity', 'currency', 'expiryCode', 'price', 'multiplier', 'commission', 'genericCode', 'qdlMult'];
export const transacFxCols = ['date', 'from', 'to', 'price', 'quantity', 'commission'];
export const transacDepositsCols = ['date', 'quantity', 'currency'];
