
import superFetch from 'modules/superFetch';

var ts = Math.round((new Date()).valueOf() / 1000 / 3600 / 4) + '';
const snapDomain = navigator.userAgent === 'ReactSnap' ? 'https://www.futuresbacktest.com' : '';

export const fetchParams = superFetch({
    url: '/api/assets/params.json',
    fallback: '/api/data/params.json',
    fallbackRetry: 2,
    fallbackRetryDelay: 5000
});

export const fetchRefSeries = superFetch({
    url: '/api/assets/refseries.json',
    fallback: '/api/data/refseries.json',
    fallbackRetry: 2,
    fallbackRetryDelay: 5000
});

export const fetchWakeup = superFetch({
    url: '/api/service/wakeup.json'
});

export const fetchSamplePortData = superFetch({
    url: snapDomain + '/api/static/items?collection=sample_portfolios&version=' + ts
});

export const fetchSamplePortDesc = superFetch({
    url: snapDomain + '/assets/data/sample_portfolios.json'
});

export const fetchConfig = superFetch({
    url: '/api/static/config.json?version=1'
});
