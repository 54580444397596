import superFetch from 'modules/superFetch';

export const saveState = superFetch({
    url: '/api/user/appstate',
    method: 'POST',
    auth: true
});

export const fetchState = superFetch({
    url: '/api/user/appstate',
    method: 'GET',
    auth: true
});

export const deleteState = superFetch({
    url: '/api/user/appstate',
    method: 'DELETE',
    auth: true
});

export const fetchPublicPortfolio = superFetch({
    url: id => '/api/user/portfolio?id=' + id,
    auth: true
});

export const setPortfolioPublic = superFetch({
    url: '/api/user/portfolio',
    method: 'POST',
    auth: true
});

export const postStaticItem = superFetch({
    url: '/api/user/staticitem',
    method: 'POST',
    auth: true
});

export const postBacktestError = superFetch({
    url: '/api/user/backtesterror',
    method: 'POST',
    auth: true
});
