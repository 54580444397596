import Parser from 'rss-parser';

const parser = new Parser({
  customFields: {
    item: ['summary','updated', 'published'],
  }
});

export const fetchBlog = () => new Promise((resolve, reject) => {
  parser.parseURL('/assets/feed.xml', function(err, feed) {
    if (err) {
      return resolve({payload: null, err});
    }
    return resolve({payload: feed, err: null});
  });
});
