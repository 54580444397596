import {Component} from 'react';
import ReactGA from 'react-ga';
import {withRouter} from 'react-router';

const snap = navigator.userAgent === 'ReactSnap';
const ga = process.env.REACT_APP_STAGE === 'prod' && !snap ? ReactGA : undefined;

if (ga) {
    ga.initialize('UA-114518873-1');
}

class Tracker extends Component {

    componentDidMount() {
        this.pageView(this.props.location);
    }
  
    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.location.search !== this.props.location.search) {
            this.pageView(this.props.location);
        }
    }

    pageView(location) {
        if (ga) {
            ga.set({ page: location.pathname });
            ga.pageview(location.pathname);
        }
        if (this.props.onPageView) {
            this.props.onPageView(location.pathname);
        }
    }

    render() {
        return null;
    }
}

export default withRouter(Tracker);
