import * as types from 'actions/actionTypes';

const defaultTableUI = {
  backtestPositions: {
    selColumns: ["name", "assetClass", "ticker", "rollContract", "monthCode", "contracts", "position", "settle", 
      "multiplier", "currency", "contractSize"]
  }
};

const tableUI = (state = defaultTableUI, action) => {

  switch (action.type) {
    case types.UI_SET_VAL_TABLE:
      if (action.payload.UIKey) {
        return {...state, [action.payload.UIKey]: action.payload.val};
      }
      return state;
    default:
      return state;
  }
};

export default tableUI;
