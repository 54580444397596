import { createSelector } from 'reselect';
import DataFrame from 'dataframe-js';

export const getTransactData = (state) => state && state.transactions;

const formatDF = (df) => (df && df.listColumns ? {
    columns: df.listColumns(),
    data: df.toArray()
}: {});

const getPositionsDF = createSelector(
  [getTransactData],
  (transac) => {
    let now = new Date();
    now = [now.getFullYear(),...[now.getMonth(),now.getDate()].map(e=>(e+'').padStart(2,'0'))].join('-');
    if (transac && transac.futures && transac.futures.data) {
      let fut = new DataFrame(transac.futures.data, transac.futures.columns)
        .filter(row => row.get('expiry') >= now);
      let details = fut
        .sortBy('date', true)
        .dropDuplicates('ticker', 'expiry', 'expiryCode');
      let pos = fut
        .groupBy('ticker', 'expiry', 'expiryCode')
        .aggregate(group => group.stat.sum('quantity'), 'position')
        .filter(row => row.get('position') !== 0)
        .leftJoin(details, ['ticker','expiry','expiryCode']);
      return pos.select('ticker', 'expiry', 'expiryCode', 'position', 'multiplier', 'genericCode', 'qdlMult');
    }
    return new DataFrame([['none']],['contract']);
  }
);

export const getPositions = createSelector(
  [getPositionsDF],
  pos => formatDF(pos)
);

const getPortfolios = state => state.portfolios;
const getParams = state => state.ui.run.posPortfolio;

export const getPosParams = createSelector(
  [getPortfolios, getParams],
  (portfolios, params) => portfolios && params ? ({
    ...portfolios[params.selPort],
    portSize: params.portSize,
    portCurr: params.portCurr
  }) : null
);


const getPos = state => state.data.positions;
const getFX = state => state.params.fx;

const getTargetPosDF = createSelector(
  [getPos, getFX],
  (pos, fx) => {
    if (pos && pos.data && fx) {
      pos = new DataFrame(pos.data, pos.columns);
      fx = new DataFrame(fx);
      return pos;
    } else {
      return null;
    }
  }
);

export const getTargetPos = createSelector(
  [getTargetPosDF],
  pos => pos ? formatDF(pos) : null
);
