import React, { Component } from 'react';
import {Form, Container, Col, Row, Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import {Helmet} from 'react-helmet';


import {federatedSignin} from 'api/auth';
import {IconGoogle, IconFacebook} from 'components/icons';
import AsyncButton from 'components/AsyncButton';
import {login} from '../../actions/authActions';

import './Login.css';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {email: '', password: '', cookie: true};
    }

    componentDidMount() {
        let {email} = queryString.parse(this.props.location.search);
        if (email) {
            this.setState({email});
        }
    }

    handleSignIn(e) {
        e.preventDefault();
        const { email, password, cookie } = this.state;
        this.props.dispatch(login({email, password, cookie}));
    }

    handleChange(column, value) {
        this.setState({[column]: value});
    }

    validator() {
        var re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.email || !this.state.password) {
            return {disabled: true, message: 'Email or password is missing'};
        } else if(!re.test(this.state.email)) {
            return {disabled: true, message: 'Email is invalid'};
        } else if(this.state.password.length<6) {
            return {disabled: true, message: 'Password should be at least 6 characters long'};
        } else {
            return {disabled: false, message: null};
        }
    }

    render() {
        const { auth } = this.props;
        const { email, password, cookie } = this.state;

        if (auth && auth.isLoggedIn) {
            return (<Redirect to={(this.props.location.state && this.props.location.state.from) || '/'} />);
        }

        const {disabled, message} = this.validator();

        return (
            <div className="Login">
                <Helmet>
                    <title>Login | FuturesBacktest</title>
                    <meta name="description" content="Login into your account" />
                </Helmet>
                <Container  className="lander">
                    <Row>
                        <Col md={12}>
                            <h2 style={{marginBottom:'1em', width:'100%', display:'block', textAlign:'center'}}>Welcome back!</h2>
                            <form onSubmit={this.handleSignIn.bind(this)}>
                                <Form.Group controlId="email">
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => this.handleChange('email', e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="password">
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => this.handleChange('password', e.target.value)} />
                                    <Form.Control.Feedback />
                                </Form.Group>
                                <Form.Group controlId="cookie">
                                    <Form.Check
                                        type="checkbox"
                                        label="Keep me signed in"
                                        checked={cookie}
                                        onChange={(e) => this.handleChange('cookie', e.target.checked)} />
                                </Form.Group>
                                <Form.Group>
                                    <AsyncButton
                                        type="submit"
                                        variant="primary"
                                        working={this.props.doingLogin}
                                        block
                                        forceReady
                                        disabled={disabled}
                                        disabledMessage={message}
                                        workingText="Logging in...">Log in</AsyncButton>
                                </Form.Group>
                            </form>
                            <p style={{textAlign:'right', marginBottom:0}}>Don't have an account yet? <Link to="/signup/">Sign up</Link></p>
                            <p style={{textAlign:'right'}}>Forgot your password? <Link to={'/resetpassword/' + (this.state.email ? '?email=' + this.state.email : '')}>Reset it</Link></p>
                            <div className="hl-or"><span>OR</span></div>
                            <Button variant="outline-light" className="btn-google" onClick={() => federatedSignin({provider: 'Google'})}>
                                <IconGoogle />Sign in with Google
                            </Button>
                            <Button variant="outline-light" className="btn-facebook" onClick={() => federatedSignin({provider: 'Facebook'})}>
                                <IconFacebook />Continue with Facebook
                            </Button>
                            <p className="social-warning">We will not post anything on your behalf</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        doingLogin: state.lifecycle.doingLogin
    };
}

export default connect(mapStateToProps)(Login);
