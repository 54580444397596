import {createAction} from 'redux-actions';
import * as types from './actionTypes';

export const runSave = createAction(types.RUN_SAVE);
export const fetchTransactions = createAction(types.FETCH_TRANSACTIONS);
export const fetchTransactionsError = createAction(types.FETCH_TRANSACTIONS_ERROR);
export const fetchTransactionsSuccess = createAction(types.FETCH_TRANSACTIONS_SUCCESS);
export const fetchReplay = createAction(types.FETCH_REPLAY);
export const fetchReplayError = createAction(types.FETCH_REPLAY_ERROR);
export const fetchReplaySuccess = createAction(types.FETCH_REPLAY_SUCCESS);
export const fetchPositions = createAction(types.FETCH_POSITIONS);
export const fetchPositionsError = createAction(types.FETCH_POSITIONS_ERROR);
export const fetchPositionsSuccess = createAction(types.FETCH_POSITIONS_SUCCESS);
