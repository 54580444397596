import * as types from '../actions/actionTypes';

const initial = {
  userDataFetched: false,
  params: 'NOT_FETCHED',
  refSeries: 'NOT_FETCHED',
  serverReady: false,
  fetchingTransact: false,
  fetchingReplay: false,
  fetchingPositions: false,
  doingBacktest: false,
  doingLogin: false,
  showError: false,
  subscribing: false,
  subscribed: false,
  sendingEmail: false,
  fetchingSeries: false,
  progress: null
};

export default (state = initial , action) => {

  var paramsType = action.payload && action.payload.type;

  switch (action.type) {

    case types.SERIES_ADD:
      return {...state, fetchingSeries: true};
    case types.SERIES_ADD_SUCCESS:
    case types.SERIES_ADD_ERROR:
      return {...state, fetchingSeries: false};

    case types.PORT_BACKTEST:
      return {...state, doingBacktest: true};
    case types.PORT_BACKTEST_SUCCESS:
    case types.PORT_BACKTEST_ERROR:
      return {...state, doingBacktest: false};

    case types.FETCH_TRANSACTIONS:
      return {...state, fetchingTransact: true};
    case types.FETCH_TRANSACTIONS_SUCCESS:
      return {...state, fetchingTransact: false};
    case types.FETCH_TRANSACTIONS_ERROR:
      return {...state,
        fetchingTransact: false,
        showError: true,
        errorMessage: (action.payload && action.payload.message) || "An error occured."};

    case types.FETCH_REPLAY:
      return {...state, fetchingReplay: true};
    case types.FETCH_REPLAY_SUCCESS:
    case types.FETCH_REPLAY_ERROR:
      return {...state, fetchingReplay: false};

    case types.FETCH_POSITIONS:
      return {...state, fetchingPositions: true};
    case types.FETCH_POSITIONS_SUCCESS:
    case types.FETCH_POSITIONS_ERROR:
      return {...state, fetchingPositions: false};


    case types.UI_SHOW_ERROR:
      return {...state,
        showError: true,
        errorMessage: (action.payload && action.payload.message) || "An error occured."
      };

    case types.UI_HIDE_ERROR:
      return {...state, showError: false};

    case types.SET_PROGRESS:
      return {...state, progress: action.payload};

    case types.LOGIN:
    case types.FETCH_LOGIN_STATE:
      return {...state, doingLogin: true};
    case types.LOGIN_SUCCESS:
    case types.FETCH_LOGIN_STATE_ERROR:
    return {...state, doingLogin: false};
    case types.LOGIN_ERROR:
      return {...state,
        doingLogin: false,
        showError: true,
        errorMessage: "Login failed: please check your email and password."};

    case types.SIGNUP:
      return {...state, doingSignup: true};
    case types.SIGNUP_SUCCESS:
      return {...state, doingSignup: false};
    case types.SIGNUP_ERROR:
      return {...state, doingSignup: false,
      showError: true,
      errorMessage: action.payload === "An account with the given email already exists." ?
        ["Signup failed: an account with this email address already exists.","Try logging in instead."] :
        "Signup failed."};

    case types.CONTACT_SEND:
      return {...state, sendingEmail: true};
    case types.CONTACT_SEND_SUCCESS:
      return {...state, sendingEmail: false, emailSent: true};
    case types.CONTACT_SEND_ERROR:
      return {...state, sendingEmail: false,
        showError: true,
        errorMessage: ["Oooops... An error occured.", "Please try again later."] };

    case types.CONTACT_SUBSCRIBE:
      return {...state, subscribing: true};
    case types.CONTACT_SUBSCRIBE_SUCCESS:
      return {...state, subscribing: false, subscribed: true};
    case types.CONTACT_SUBSCRIBE_ERROR:
      return {...state, subscribing: false,
        showError: true,
        errorMessage: ["Oooops... An error occured.", "Please try again later."] };

    case types.PARAMS_LOAD:
      return {...state, [paramsType]: 'FETCHING'};

    case types.PARAMS_LOAD_SUCCESS:
      return {...state, [paramsType]: 'FETCHED'};

    case types.PARAMS_LOAD_ERROR:
      return {...state,
        [paramsType]: 'ERROR',
        showError: true,
        errorMessage: ["Oooops... An error occured.", "Unable to fetch data from the server. Please refresh the page or try again later."] };

    case types.FETCH_STATE_SUCCESS:
      return {...state, userDataFetched: true};

    case types.FETCH_STATE_ERROR:
      return {...state,
        showError: true,
        errorMessage: ["Oooops... An error occured.", "Unable to fetch data from the server. Please refresh the page or try again later."] };

    case types.WAKEUP_SUCCESS:
      return {...state, serverReady: true};

    default:
      return state;
  }
};
