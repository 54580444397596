import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';

import './cookiesConsent.css';

class CookiesConsent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'accepted': document.cookie.replace(/(?:(?:^|.*;\s*)cookies-consent\s*=\s*([^;]*).*$)|^.*$/, '$1')
        };
        this.accept = this.accept.bind(this);
    }

    accept() {
        document.cookie = 'cookies-consent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/ ; ';
        this.setState({accepted: true});
    }

    render() {

        return this.state.accepted ? null : <div id="cookies-consent">
            <p>We use cookies for identification and statistics. Please review our <a href="/docs/privacy/">Privacy Policy</a> to learn more.</p>
            <Button onClick={this.accept} variant="secondary">Accept and close</Button>
        </div>;
    }

}

export default CookiesConsent;