import * as types from 'actions/actionTypes';
import * as uiKeys from 'actions/uiKeys';

// reducer for "overview" view
const defaultOverview = {
  [uiKeys.OVERVIEW_TAB]: "SPECS"
};

const overviewUI = (state = defaultOverview, action) => {

  switch (action.type) {
    case types.UI_SET_VAL_OVERVIEW:
      if (action.payload.UIKey) {
        return {...state,[action.payload.UIKey]: action.payload.val};
      }
      return state;
    default:
      return state;
  }
};

export default overviewUI;
