import {combineReducers} from 'redux';
import * as types from 'actions/actionTypes';

import auth from 'views/login/reducers/authReducer';
import user from 'views/account/reducers/accountReducer';
import uiExplore from 'views/strategies/reducers/ui';
import uiOverview from 'views/overview/reducers/ui';
import uiBacktest from 'views/backtest/reducers/ui';
import uiRun from 'views/run/reducers/ui';
import lifecycle from './lifecycleReducer';

import uiTable from 'reducers/uiTableReducer';

import portfolios from 'views/backtest/reducers/portfolios';
import backtestData from 'views/backtest/reducers/backtest';

import series from 'views/strategies/reducers/series';
import {seriesData, ratesData} from 'views/strategies/reducers/data';
import {transactions, replay, positions} from 'views/run/reducers/run';

import params from './paramsReducer';

const tree = combineReducers({
  series,
  portfolios,
  auth,
  user,
  ui: combineReducers({
    explore: uiExplore,
    overview: uiOverview,
    backtest: uiBacktest,
    run: uiRun,
    tables: uiTable
  }),
  params,
  transactions,
  lifecycle,
  data: combineReducers({
    backtest: backtestData,
    series: seriesData,
    rates: ratesData,
    replay: replay,
    positions: positions
  })
});

const rootReducer = (state, action) => {
  if (action.type === types.FETCH_STATE_SUCCESS) {
    let {email, name} = state.auth && state.auth.user;
    let user = action.payload.user || {email, name};
    state = {
      ...state,
      series: action.payload.series,
      portfolios: action.payload.portfolios,
      ui: action.payload.ui,
      transactions: action.payload.transactions,
      user
    };
  }
  if (action.type === types.LOGOUT) {
    state = {};
  }
  return tree(state, action);
};

export default rootReducer;
