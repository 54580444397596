import React, {Component} from 'react';
import {ProgressBar} from 'react-bootstrap';

const TICK_INTERV = 200;

class ProgressModal extends Component {

  state = {
      nbDots: 1,
      status: 'hide'
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.status && JSON.stringify(prevProps.status)) !==
      (this.props.status && JSON.stringify(this.props.status))) {
      if (this.props.status) {
        this.setState({
          message: this.props.status.message || 'Working',
          percent: this.props.percentFrom || 0,
          status: 'show',
          timerProgress: setInterval(this.tickProgress.bind(this), TICK_INTERV),
          timerDots: this.state.timerDots || setInterval(() => this.setState({nbDots: (this.state.nbDots+1) % 4}), 300)
        });
      } else {
        if (this.state.timerDots) {
          clearInterval(this.state.timerDots);
        }
        if (this.state.timerProgress) {
          clearInterval(this.state.timerProgress);
        }
        this.setState({
          message: 'Done.',
          percent: 100,
          status: 'fade',
          nbDots: 0,
          timerDots: null,
          timerProgress: null
        }, () => setTimeout(() => this.setState({'status': 'hide'}), 1000));
      }
    }
  }

  tickProgress() {
    let percentFrom = parseInt(this.props.status && this.props.status.percentFrom) || 0;
    let percentTo = parseInt(this.props.status && this.props.status.percentTo) || 97;
    let duration = parseInt(this.props.status && this.props.status.duration) || 2000;
    let percent = Math.max(this.state.percent, percentFrom);
    percent += (percentTo - percentFrom) * TICK_INTERV / duration;
    if (percent > percentTo) {
        if (this.state.timerProgress) clearInterval(this.state.timerProgress);
        this.setState({percent: percentTo, timerProgress: null});
    } else {
        this.setState({percent});
    }
  }

  render() {
    if (this.state.status === 'hide') {
      return <div></div>;
    } else {
      return (
        <div
          className={"progress-modal" + (this.state.status === 'fade' ? " fading" : "")}>
          <span>{this.state.message}{".".repeat(this.state.nbDots)}</span>
          <ProgressBar now={this.state.percent} />
        </div>
      );
    }
  }
}

export default ProgressModal;
