import * as types from 'actions/actionTypes';

export default (state = {} , action) => {
  switch (action.type) {

    case types.USER_UPDATE_ATTRIBUTES:
      return {...state, attributes: action.payload};

    case types.LOGOUT:
      return {};

    default:
      return state;
  }
};
