import React, { Component } from 'react';
import {
    Form,
    Container, Col, Row, Tooltip, OverlayTrigger
} from 'react-bootstrap';
import {connect} from 'react-redux';
import {login} from 'actions/authActions';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import AsyncButton from 'components/AsyncButton';

import './Login.css';

class Invite extends Component {

    constructor(props) {
        super(props);
        this.state = {email: '', key: '', password: '', password2: ''};
    }

    componentDidMount(){
        let {email, key} = queryString.parse(this.props.location.search);
        if (email && key) {
            this.setState({email, key});
        }
    }

    handleSignIn(e) {
        e.preventDefault();
        const { email, password, key } = this.state;
        this.props.dispatch(login({email, password: key, newPassword: password}));
    }

    handleChange(column, value) {
        this.setState({[column]: value});
    }

    validator() {
        var re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.email || !this.state.password || !this.state.key) {
            return {disabled: true, message: 'Email, temporary password or new password are missing'};
        } else if(this.state.password !== this.state.password2) {
            return {disabled: true, message: 'New passwords don\'t match'};
        } else if(!re.test(this.state.email)) {
            return {disabled: true, message: 'Email is invalid'};
        } else if(this.state.password.length<6) {
            return {disabled: true, message: 'New password must be at least 6 characters long'};
        } else {
            return {disabled: false, message: null};
        }
    }

    render() {
        const { auth } = this.props;
        const { email, key, password, password2} = this.state;

        const {disabled, message} = this.validator();

        const tooltip = (
            <Tooltip id="tooltip_signup">{message}</Tooltip>
        );

        const button = (
            <AsyncButton
                type="submit"
                variant="primary"
                style={disabled ? {pointerEvents:'none'} : {}}
                working={this.props.doingLogin}
                disabled={password === '' || password !== password2 || !email}
                block
                workingText="Logging in...">Log in</AsyncButton>
        );

        return (
            <div className="Login">
                <Container className="lander">
                    <Row>
                        <Col md={12}>
                            <h2 style={{marginBottom:'1em', width:'100%', display:'block', textAlign:'center'}}>Welcome!</h2>
                            {(!auth || !auth.isLoggedIn) && <div>
                                <p>You've been invited to join. Please enter you connection info below:</p>
                                <form onSubmit={this.handleSignIn.bind(this)}>
                                    <Form.Group
                                        controlId="email">
                                        <Form.Control
                                            type="email"
                                            placeholder="Your email"
                                            value={email}
                                            onChange={(e) => this.handleChange('email', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group
                                        controlId="key">
                                        <Form.Control
                                            type="text"
                                            placeholder="Temporary password"
                                            value={key}
                                            onChange={(e) => this.handleChange('key', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group
                                        controlId="password">
                                        <Form.Control
                                            type="password"
                                            value={password}
                                            placeholder="Choose a password"
                                            onChange={(e) => this.handleChange('password', e.target.value)} />
                                        <Form.Control.Feedback />
                                    </Form.Group>
                                    <Form.Group
                                        controlId="password2">
                                        <Form.Control
                                            type="password"
                                            placeholder="Repeat your password"
                                            value={password2}
                                            onChange={(e) => this.handleChange('password2', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group>
                                        {disabled &&
                        <OverlayTrigger placement="right" overlay={tooltip}>
                            <div style={{display:'block',cursor:'not-allowed'}}>{button}</div>
                        </OverlayTrigger>}
                                        {!disabled && button}
                                    </Form.Group>
                                </form>
                                <p style={{textAlign:'right', marginBottom:0}}>Already have an account? <Link to="/login">Log in</Link></p>
                            </div>}
                            {auth && auth.isLoggedIn && <div>
                                <p style={{textAlign: 'center'}}>You are now logged in. Enjoy your visit!</p>
                            </div>}
                        </Col>
                    </Row>
                </Container>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        doingLogin: state.lifecycle.doingLogin
    };
}

export default connect(mapStateToProps)(Invite);
