import * as types from './actionTypes';
import {createAction} from 'redux-actions';

export const paramsLoad = createAction(types.PARAMS_LOAD);
export const paramsLoadSuccess = createAction(types.PARAMS_LOAD_SUCCESS);
export const paramsLoadError = createAction(types.PARAMS_LOAD_ERROR);
export const fetchStateSuccess = createAction(types.FETCH_STATE_SUCCESS);
export const fetchStateError = createAction(types.FETCH_STATE_ERROR);
export const wakeupSuccess = createAction(types.WAKEUP_SUCCESS);


export const setProgress = createAction(types.SET_PROGRESS);
