import * as types from './actionTypes';
import {createAction} from 'redux-actions';

export const fetchLoginState = createAction(types.FETCH_LOGIN_STATE);
export const fetchLoginStateError = createAction(types.FETCH_LOGIN_STATE_ERROR);

export const login = createAction(types.LOGIN);
export const loginSuccess = createAction(types.LOGIN_SUCCESS);
export const loginError = createAction(types.LOGIN_ERROR);

export const confirmUser = createAction(types.CONFIRM_USER);
export const confirmUserSuccess = createAction(types.CONFIRM_USER_SUCCESS);
export const confirmUserError = createAction(types.CONFIRM_USER_ERROR);

export const logout = createAction(types.LOGOUT);
export const clickLogout = createAction(types.CLICK_LOGOUT);

export const signup = createAction(types.SIGNUP);
export const signupSuccess = createAction(types.SIGNUP_SUCCESS);
export const signupError = createAction(types.SIGNUP_ERROR);
