import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from 'actions/actionTypes';

import {
  fetchLoginStateError,
  loginError,
  loginSuccess,
  signupSuccess,
  signupError,
  confirmUserSuccess,
  confirmUserError
} from 'actions/authActions';

import {paramsLoad} from 'actions/paramsActions';

import {
  getCurrentUser,
  cognitoSignIn,
  cognitoSignup,
  signOut,
  cognitoConfirm
} from 'api/auth';


// look for a currently signed in user
function* fetchLoginState(action) {
  try {
    const user = yield call(getCurrentUser);
    yield put(loginSuccess({...user, ...action.payload}));
    yield put(paramsLoad({type: 'params'}));
  } catch(error) {
    yield put(fetchLoginStateError(action.payload));
  }
}
export function* handleFetchLoginState() {
  yield takeEvery(types.FETCH_LOGIN_STATE, fetchLoginState);
}

// do login
function* doLogin(action){
  const { email, password, cookie } = action.payload;
  if(email && password) {
    try {
      yield call(cognitoSignIn, {email, password, cookie: !!cookie});
    } catch(error) {
      yield put(loginError(`${error.message}`));
    }
  } else {
    yield put(loginError('Please set email and password.'));
  }
}
export function* handleLogin() {
  yield takeEvery(types.LOGIN, doLogin);
}

// do signup
function* doSignup(action){
  let { email, name, password } = action.payload;
  name = name || "John Doe";
  if (email && password) {
    try{
      const payload = yield call(cognitoSignup, {email, name, password});
      yield put(signupSuccess(payload));
    } catch(error){
      yield put(signupError(`${error.message}`));
    }
  } else {
    yield put(signupError('Please set at least email and password.'));
  }
}
export function* handleSignup() {
  yield takeEvery(types.SIGNUP, doSignup);
}

// do logout
function* doLogout(action){
  const {global=false} = action || {};
  try{
    yield call(signOut, global);
//    yield put(logout());
  } catch(error){}
}
export function* handleLogout() {
  yield takeEvery(types.CLICK_LOGOUT, doLogout);
}

// confirm user
function* doConfirmUser(action) {
  const {username, key} = action.payload;
  try{
    const payload = yield call(cognitoConfirm, username, key);
    yield put(confirmUserSuccess(payload));
  } catch(err) {
    yield put(confirmUserError(err));
  }
}
export function* handleConfirmUser(){
  yield takeEvery(types.CONFIRM_USER, doConfirmUser);
}
