import React, { Component } from 'react';
import ReactLoading from 'react-loading';


class Loading extends Component {


  render() {
    
    var styles1 = {
      width: "100%",
      padding: "150px 0",
      textAlign: "center"
    };
    var styles2 = {
      margin: "auto",
      width: "40px"
    };

    return (
      <div style={styles1}>
        <ReactLoading type="spin" color="#2c3e50" style={styles2} />
      </div>
    );
  }
}

export default Loading;
