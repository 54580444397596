import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import * as types from 'actions/actionTypes';
import {asyncFetch} from 'sagas/asyncFetch';
import {getSeries} from 'api/futures';
import {seriesAddSuccess, seriesAddError} from 'actions/seriesActions';
import * as Nprogress from 'nprogress';
import shortid from 'shortid';

const getContractsParams = state => state.params.contracts;
const getData = state => state.data.series;

function* fetchSeries(action) {
    const seriesData = yield select(getData);
    const { payload, err } = yield call(asyncFetch, getSeries, action.payload, {
        initMessage: 'Fetching data', 
        initPercentFrom: 0, 
        initPercentTo: 95,
        initDuration: 6000
    });

    if (payload && !err) {
        const contracts = yield select(getContractsParams);
        const contract = contracts.find(e => e.genericCode === payload.params.genericCode);
        yield put(seriesAddSuccess({
            id: action.payload.id, 
            name: contract.contractName,
            disambig: seriesData[action.payload.id] ? seriesData[action.payload.id].disambig : undefined,
            ...payload,
            version: shortid.generate()
        }));
    } else {
        yield put(seriesAddError(action.payload));
    }

}

export function* handleSeriesAdd() {
    yield takeEvery(types.SERIES_ADD, fetchSeries);
}

const getSeriesData = state => state.series.byId;

function* applyCodeToAll(action) {
    let genericCode = action.payload;
    if (genericCode) {
        const series = yield select(getSeriesData);
        Nprogress.configure({ trickleRate: 0.05, trickleSpeed: 500 });
        Nprogress.start();
        let newSeries = Object.keys(series)
            .map(e => series[e] && series[e].genericCode !== genericCode && {...series[e], genericCode})
            .filter(e => !!e);
        let data = yield all(newSeries.map(e=>call(getSeries, e)));
        for (let i=0; i<data.length; i++){
            let {payload, err} = data[i];
            if (payload && !err) {
                yield put(seriesAddSuccess({...newSeries[i], data:payload}));
            } else {
                yield put(seriesAddError(newSeries[i]));
            }
        }
        yield call(Nprogress.done);
    }
}

export function* handleSeriesApplyCode() {
    yield takeEvery(types.SERIES_APPLY_CODE, applyCodeToAll);
}
