import { takeEvery, call, put } from 'redux-saga/effects';
import * as types from 'actions/actionTypes';
import { fetchParams, fetchRefSeries, fetchSamplePortData, 
    fetchSamplePortDesc, fetchConfig } from 'api/params';
import { fetchBlog } from 'api/blog';

import { paramsLoadSuccess, paramsLoadError } from 'actions/paramsActions';

function* loadParams(action) {
    let type = (action.payload && action.payload.type) || 'params';
    let fetchers = {
        params: fetchParams,
        config: fetchConfig,
        refSeries: fetchRefSeries, 
        blog: fetchBlog,
        samplePortDesc: fetchSamplePortDesc,
        samplePortData: fetchSamplePortData
    };
    const { payload, err } = yield call(fetchers[type]);
    if (payload && !err) {
        yield put(paramsLoadSuccess({type, data:payload}));
    } else {
        yield put(paramsLoadError({type}));
    }
}

export function* handleParamsLoad() {
    yield takeEvery(types.PARAMS_LOAD, loadParams);
}
