export const EXPLORE_TYPE = 'exploreType';
export const SERIES_GRAPH_PARAMS = 'SeriesGraphParams';
export const RATES_GRAPH_PARAMS = 'RatesGraphParams';
export const SERIES_RANGE = 'seriesRange';
export const SERIES_VIS = 'seriesVis';
export const SERIES_FORM_PAGE = 'seriesFormPage';
export const SEL_SERIES = 'selSeries';
export const BACKTEST_GRAPH_PARAMS = 'backtestGraphParams';
export const BACKTEST_ASSETS_TABLE = 'backtestAssetsTable';
export const BACKTEST_VIS = 'backtestVis';
export const BACKTEST_TAB_SEL = 'backtestTabSel';
export const PORT_SIZE = 'portSize';
export const PORT_SEL_PILL = 'portSelPill';
export const SEL_PORT = 'selPort';
export const OVERVIEW_VIS = 'overviewVis';
export const OVERVIEW_SPECS_COLS = 'overviewSpecsCols';
export const OVERVIEW_SPECS_PARAMS = 'overviewSpecsParams';
export const OVERVIEW_SPECS_SORT = 'overviewSpecsSort';
export const OVERVIEW_INDIC = 'overviewIndic';
export const OVERVIEW_CORREL_PARAMS = 'overviewCorrelParams';
export const POSITIONS_SEL = 'positionsSel';
export const CONTRIB_SEL = 'contribSel';
export const CONTRIB_VIS = 'contribVis';
export const CONTRIB_GRAPH_PARAMS = 'contribGraphParams';
export const RUN_TAB_SEL = 'runTabSel';
export const FUT_TABLE_PARAMS = 'futTableParams';
export const FX_TABLE_PARAMS = 'fxTableParams';
export const DEP_TABLE_PARAMS = 'depTableParams';
export const TRANSAC_SEL_PILL = 'transacSelPill';
export const POS_SEL_PILL = 'posSelPill';
export const POS_PORTFOLIO = 'posPortfolio';
export const REPLAY_GRAPH_PARAMS = 'replayGraphParams';
export const OVERVIEW_TAB = 'overviewTab';
export const OVERVIEW_CORREL = 'overviewCorrel';
