import * as types from 'actions/actionTypes';
import * as uiKeys from 'actions/uiKeys';

// reducer for "explore" view
const defaultExplore = {
  [uiKeys.EXPLORE_TYPE]: "FUTURES"
};

const exploreUI = (state = defaultExplore, action) => {
  let seriesVis = state[uiKeys.SERIES_VIS] || {};
  switch (action.type) {
    case types.UI_SET_VAL_EXPLORE:
      if (action.payload.UIKey) {
        return {...state,[action.payload.UIKey]: action.payload.val};
      }
      return state;
    case types.SERIES_ADD:
      if (action.payload && action.payload.id) {
        seriesVis[action.payload.id] = true;
      }
      return {...state, seriesVis, selSeries: action.payload && action.payload.id};
    case types.SERIES_ADD_ERROR:
    case types.SERIES_DELETE:
      if (action.payload) {
        let sel = Object.keys(seriesVis).filter(e=>(e!==action.payload)&&(e.indexOf("__")===-1));
        if (sel.length>0) return {...state, selSeries: sel[0]};
      }
      return {...state, selSeries: undefined};
    default:
      return state;
  }
};

export default exploreUI;
