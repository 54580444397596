import React, {Component, lazy, Suspense} from 'react';
import {Router, Route, Redirect, Switch, Link} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {LinkContainer} from 'react-router-bootstrap';
import {Hub} from 'aws-amplify';
import {Helmet} from 'react-helmet';

import {connect} from 'react-redux';

import {fetchLoginState, clickLogout, loginSuccess, logout} from 'actions/authActions';
import {wakeupSuccess, paramsLoad} from 'actions/paramsActions';

import {uiHideError} from 'actions/uiActions';
import {
  Nav,
  Navbar,
  NavDropdown,  
  Modal, Container
} from 'react-bootstrap';

import 'App.css';

import {fetchWakeup} from 'api/params';

import Loading from 'components/Loading';
import ProgressModal from 'components/ProgressModal';
import CookiesConsent from 'components/CookiesConsent';
import ScrollToTop from 'components/ScrollToTop';
import Tracker from 'components/Tracker';
import WebFont from 'webfontloader';
import {IconFB} from 'components/icons';

// Import views
import Invite from 'views/login/Invite';
import Login from 'views/login/Login';
import Signup from 'views/login/Signup';
import ConfirmUser from 'views/login/ConfirmUser';
import ResetPassword from 'views/login/ResetPassword';
import Home from 'views/home/Home';
import NotFound from 'views/notfound/NotFound';
import { logger } from 'modules/logger';


const history = createBrowserHistory();
const snap = navigator.userAgent === 'ReactSnap';

// Import code-splitted views
const toFun = Component => props => <Component {...props} />;
const Explore = toFun(lazy(() => import('views/explore/Explore' /* webpackPrefetch: true */)));
const Strategies = toFun(lazy(() => import('views/strategies/Strategies')));
const Backtest = toFun(lazy(() => import('views/backtest/Backtest')));
//const Run = toFun(lazy(() => import('views/run/Run')));
const Overview = toFun(lazy(() => import('views/overview/Overview')));
const Contact = toFun(lazy(() => import('views/contact/Contact')));
const Account = toFun(lazy(() => import('views/account/Account')));

const PrivateRoute = ({component: Component, auth, ...rest}) => (
  <Route {...rest} render={props => (
      auth.isLoggedIn
      ? (<Component {...props}/>)
      : (
        auth.pathname && !snap
        ? (<Redirect to={{
            pathname: "/login/",
            state: { from: props.location }
          }}/>)
        : <Loading/>))}/>);


class App extends Component {

  state = {
    windowLoaded: false,
    navbarStyle: document.location.pathname === '/' ? 'static' : 'fixed'
  };

  componentDidMount() {

    window.addEventListener('load', this.handleWindowLoad);

    WebFont.load({
      google: {
        families: ['Roboto:400,500,700', 'Open Sans']
      }
    });
    
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          logger("signed in");
          logger(data);
          if (data) {
            setTimeout(() => {
              data.getUserAttributes((err, data) => {
                if (err) {
                  logger(err);
                }
                if(data) {
                  this.props.dispatch(loginSuccess({
                    user: data.reduce((a, e)=>({...a, [e.Name]: e.Value}), {})
                  }));
                  this.props.dispatch(paramsLoad({type: 'params'}));
                }
              });
            }, 500); 
          }
          break;
        case "signOut":
          logger("signed out");
          this.props.dispatch(logout());
          break;
        // no default
      }
    });

    const {pathname} = document.location;
    if (pathname.startsWith('/confirmuser')) {
      this.props.dispatch(clickLogout());
    } else {
      this.props.dispatch(fetchLoginState({
        pathname: pathname === "/login/"
          ? "/"
          : pathname
      }));
    }
    if (!snap) {
      fetchWakeup().then(res => !res.err && this.props.dispatch(wakeupSuccess()));
      this.props.dispatch(paramsLoad({type: 'config'}));
      this.props.dispatch(paramsLoad({type: 'blog'}));
    }
    history.listen(this.handleSwitchPage);

  }

  componentWillUnmount() {
    window.removeEventListener('load', this.handleWindowLoad);
  }

  handleWindowLoad = this.handleWindowLoad2.bind(this);
  handleWindowLoad2() {
    this.setState({windowLoaded: true});
  }

  handleSignOut = this.handleSignOut2.bind(this);
  handleSignOut2(e) {
    e.preventDefault();
    this.props.dispatch(clickLogout());
  }

  handleSwitchPage = this.handleSwitchPage2.bind(this);
  handleSwitchPage2(location) {
    this.setState({navbarStyle: location.pathname === "/" ? "static" : "fixed"});
  }

  render() {
    const {auth} = this.props;
    const mainUri = 'https://www.futuresbacktest.com';
    const navbarStyle = this.state.navbarStyle === 'fixed' ? {fixed: 'top'} : {};

    let errorMessage = this.props.errorMessage && (
        Array.isArray(this.props.errorMessage) ? this.props.errorMessage : [this.props.errorMessage]);
    errorMessage = errorMessage && errorMessage.map((e,i)=>(<p key={"p_"+i}>{e}</p>));

    return (<React.Fragment><Router history={history}><Tracker />
        <Helmet>
          <title>FuturesBacktest</title>
          <meta name="description" content="Build and backtest futures contracts portfolios through a simple graphical interface. Different flavours of trend, carry and value strategies and many backtesting options are available out of the box." />
          <meta name="keywords" content="backtesting,backtest,finance,futures,contracts,futures contracts,trading,strategies,quant,quantitative,algo,algorithmic trading,portfolio,screener,interactive brokers,trend following,trend,carry,value,factor investing,factors,hedge fund,long short,leveraged strategies,CTA" />
        </Helmet>
        <ScrollToTop>
          <div className={"main" + (this.state.windowLoaded && !snap ? " loaded" : "")}>
            <Navbar {...navbarStyle} expand="lg" bg="primary" variant="dark" collapseOnSelect>
            <Container>
              <LinkContainer to="/"><Navbar.Brand href="/"><IconFB size={22} /> FuturesBacktest</Navbar.Brand></LinkContainer>
                <Navbar.Toggle />
                <Navbar.Collapse>
                  <Nav className="mr-auto">
                    <LinkContainer to="/explore/"><Nav.Link>Explore</Nav.Link></LinkContainer>
                    <LinkContainer to="/overview/"><Nav.Link>Overview</Nav.Link></LinkContainer>
                    <LinkContainer to="/strategies/"><Nav.Link>Strategies</Nav.Link></LinkContainer>
                    <LinkContainer to="/backtest/"><Nav.Link>Backtest</Nav.Link></LinkContainer>
                  </Nav>
                  <Nav>
                    <Nav.Link
                      href={mainUri + "/docs/"}
                      target="_blank">Docs</Nav.Link>
                    <Nav.Link
                      href={mainUri + "/blog/"}
                      target="_blank">Blog</Nav.Link>
                    {
                      auth.isLoggedIn && <NavDropdown title="Me" id="basic-nav-dropdown" alignRight>
                          <LinkContainer to="/account/">
                            <NavDropdown.Item>Account details</NavDropdown.Item>
                          </LinkContainer>
                          <NavDropdown.Item onClick={this.handleSignOut}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    }
                    {
                      !auth.isLoggedIn && <LinkContainer to="/login/">
                          <Nav.Link>Login</Nav.Link>
                        </LinkContainer>
                    }
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            <div style={{
                width: "100%",
                minHeight: "calc(100vh-145px)",
                ...(this.state.navbarStyle === 'fixed' ? {marginTop: "65px"} : {})
              }}>
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route path="/login/" component={Login}/>
                  <Route path="/" exact component={Home}/>
                  <Route path="/contact/" exact component={Contact}/>
                  <Route path="/explore/" component={Explore} auth={auth} />
                  <PrivateRoute path="/strategies" component={Strategies} auth={auth} />
                  <PrivateRoute path="/backtest/:portfolioId?" component={Backtest} auth={auth} />
                  <PrivateRoute path="/overview/" component={Overview} auth={auth} />
                  <PrivateRoute path="/account/" component={Account} auth={auth} />
                  <Route path="/signup/" component={Signup}/>
                  <Route path="/invite/" component={Invite}/>
                  <Route path="/confirmuser/" component={ConfirmUser}/>
                  <Route path="/resetpassword/" component={ResetPassword}/>
                  <Route path="/404" component={NotFound}/>
                  <Redirect to="/404" />
                </Switch>
              </Suspense>
            </div>
          </div>
        <div id="footer">
          <div>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={mainUri + "/docs/terms/"} target="_blank">Terms of service</a>{" - "}
            <Link to="/contact/">Contact</Link>{" - "}
            <Link to="/">FuturesBacktest 2020</Link>
          </div>
        </div>
        </ScrollToTop>
      </Router>
      <Modal show={this.props.showError} 
      onHide={() => this.props.dispatch(uiHideError())}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
      </Modal>
      <ProgressModal status={this.props.progress} />
      {!snap && false && <CookiesConsent />}
    </React.Fragment>);
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    showError: state.lifecycle.showError,
    errorMessage: state.lifecycle.errorMessage,
    progress: state.lifecycle.progress,
    cookiesConsent: state.params.uiParams?.cookies_consent
  };
}

export default connect(mapStateToProps)(App);
