import * as types from './actionTypes';
import {createAction} from 'redux-actions';

export const uiSetValExplore = (UIKey, payload) => ({
  type: types.UI_SET_VAL_EXPLORE,
  payload: {
    UIKey,
    val: payload
  }
});

export const uiSetValOverview = (UIKey, payload) => ({
  type: types.UI_SET_VAL_OVERVIEW,
  payload: {
    UIKey,
    val: payload
  }
});

export const uiSetValBacktest = (UIKey, payload) => ({
  type: types.UI_SET_VAL_BACKTEST,
  payload: {
    UIKey,
    val: payload
  }
});

export const uiSetValRun = (UIKey, payload) => ({
  type: types.UI_SET_VAL_RUN,
  payload: {
    UIKey,
    val: payload
  }
});

export const uiSetValTable = (UIKey, payload) => ({
  type: types.UI_SET_VAL_TABLE,
  payload: {
    UIKey,
    val: payload
  }
});


export const uiShowError = createAction(types.UI_SHOW_ERROR);
export const uiHideError = createAction(types.UI_HIDE_ERROR);
export const setProgress = createAction(types.SET_PROGRESS);
