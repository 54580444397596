import * as types from 'actions/actionTypes';
import { combineReducers } from 'redux';

// reducer for series
const seriesById = (state = {}, action) => {

    // eslint-disable-next-line no-unused-vars
    let pl, subSeries, del1, newState1, del2, newState2;

    pl = action.payload;

    switch (action.type) {

        // add / update series and initiate visibility array
        case types.SERIES_ADD:
            return {...state, [action.payload.id]: {...action.payload}};

        // add / update series and initiate visibility array
        case types.SERIES_ADD_SUCCESS:
            subSeries = [{id:'pos', name:'Position'}, {id:'rollPoints', name:'Roll days'}, {id: 'daysToExp', name: 'Days to exp.'}];
            subSeries = [...subSeries, ...pl.series.columns.slice(5).map(e => ({id:e, name:e}))];
            return {...state, [pl.id]: {...pl.params, subSeries}};

        // add / update series and initiate visibility array
        case types.SERIES_ADD_ERROR:
            // eslint-disable-next-line no-unused-vars
            ({[action.payload.id+'']: del1, ...newState1} = state);
            return newState1;

        // delete series
        case types.SERIES_DELETE:
            // eslint-disable-next-line no-unused-vars
            ({[action.payload+'']:del2, ...newState2} = state);
            return newState2;

        default:
            return state;
    }
};

// reducer for series ids
const seriesIds = (state = [], action) => {
    switch (action.type) {
    case types.SERIES_ADD_SUCCESS:
        if (state.indexOf(action.payload.id)<0) {
            return [...state, action.payload.id];
        }
        return state;
    case types.SERIES_DELETE:
        return state.filter(e => (e !== action.payload));
    default:
        return state;
    }
};

// reducer for selected series
const selected = (state = 0, action) => {
    switch (action.type) {
    case types.SERIES_SELECT:
        return action.payload;
    case types.SERIES_ADD_SUCCESS:
        return action.payload.id;
    case types.SERIES_DELETE:
        return 0;
    default:
        return state;
    }
};

export default combineReducers({
    byId: seriesById,
    idsList: seriesIds,
    selected
});
