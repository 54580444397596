import * as types from 'actions/actionTypes';
import {formatDate} from 'modules/format';

const tryParseJSON = (source, def={}) => {
  let res;
  try{
    res = JSON.parse(source);
  } catch(e){}
  return res || def;
}

const parseMult = (mult, ticker) => {
  let p = parseFloat(mult);
  return p ? {[ticker]: p} : tryParseJSON(mult, {});
}

// reducer for parameters
const params = (state = {}, action) => {
  switch (action.type) {
    case types.PARAMS_LOAD_SUCCESS:
      switch(action.payload.type) {
        case 'params':
          let contracts = (action.payload.data.contracts || []).map(
            c => ({
              rollContract: c.refRoll,
              lastPrices: tryParseJSON(c.lastPrices,[]).reduce((a,e)=>({...a, [e.rollContract]:e}),{}),
              genericCode: c.genericCode,
              indValues: tryParseJSON(c.indicators_values, []).reduce((a,e)=>({...a, [e.indicator]:e}),{}),
              indicator: c.indicator,
              currency: c.currency,
              multiplier: parseMult(c.multiplier, c.ticker),
              description: c.description,
              contractName: c.name,
              type: c.type,
              ticker: c.ticker,
              startDate: formatDate(new Date(c.startDate)),
              depth: c.nbContracts
            })
          );
          let seriesDesc = action.payload.data.series;
          let fx = action.payload.data.fx;
          return {...state, contracts, series: seriesDesc, fx};
        case 'refSeries':
          let refSeries = action.payload.data.series_data;
          refSeries.data = refSeries.data && refSeries.data.map(r => [r[0]*1000, ...r.slice(1)]);
          return {...state, refSeries};
        default:
          return {...state, [action.payload.type]: action.payload.data};
  
      }

    case types.FETCH_STATE_SUCCESS:
      return {...state, stateFetchStatus: "FETCHED"};

    case types.FETCH_STATE_ERROR:
      return {...state, stateFetchStatus: "ERROR"};

    default:
      return state;
  }
};

export default params;
