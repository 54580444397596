import * as types from './actionTypes';
import {createAction} from 'redux-actions';


export const portSelect = createAction(types.PORT_SELECT);
export const portUpdateAssets = createAction(types.PORT_UPDATE_ASSETS);
export const portUpdateAssetsSideEffects = createAction(types.PORT_UPDATE_ASSETS_SIDE_EFFECTS);
export const portUpdateClasses = createAction(types.PORT_UPDATE_CLASSES);
export const portUpdateStrats = createAction(types.PORT_UPDATE_STRATS);
export const portSwitchStrats = createAction(types.PORT_SWITCH_STRATS);
export const portUpdateOptions = createAction(types.PORT_UPDATE_OPTIONS);
export const portNew = createAction(types.PORT_NEW);
export const portDelete = createAction(types.PORT_DELETE);
export const portSave = createAction(types.PORT_SAVE);
export const portBacktest = createAction(types.PORT_BACKTEST);
export const portBacktestSuccess = createAction(types.PORT_BACKTEST_SUCCESS);
export const portBacktestError = createAction(types.PORT_BACKTEST_ERROR);
export const portAddPublic = createAction(types.PORT_ADD_PUBLIC);
export const portAddPublicSuccess = createAction(types.PORT_ADD_PUBLIC_SUCCESS);
export const portAddPublicError = createAction(types.PORT_ADD_PUBLIC_ERROR);
