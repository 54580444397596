import awsIot from 'aws-iot-device-sdk';
import {logger} from 'modules/logger';

class Notifications{

    constructor(onMessage=null) {
        this.onMessage = onMessage || logger;
        this.client = {};
    }

    connect({topic, iotEndpoint, region, accessKey, secretKey, sessionToken}) {
        this.iotTopic = topic;
        let params = {
            region: region,
            protocol: 'wss',
            accessKeyId: accessKey,
            secretKey: secretKey,
            sessionToken: sessionToken,
            port: 443,
            host: iotEndpoint
        };
        this.client = awsIot.device(params);
        this.client.on('connect', this.onConnect.bind(this));
        this.client.on('message', (topic, message) => {
            let decoded;
            try {
                decoded = JSON.parse(message.toString());
            } catch(e) {
                decoded = false;
            }
            this.onMessage(decoded);
        });
        this.client.on('close', ()=>logger('Connection failed'));
    }

    send(message) {
        this.client.publish(this.iotTopic, message);
    }

    onConnect() {
        this.client.subscribe(this.iotTopic);
        logger('Connected');
    }
}

export default Notifications;
