var watchChanges = [];

// auth actions
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const FETCH_LOGIN_STATE = 'FETCH_LOGIN_STATE';
export const FETCH_LOGIN_STATE_ERROR = 'FETCH_LOGIN_STATE_ERROR';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const CONFIRM_USER = 'CONFIRM_USER';
export const CONFIRM_USER_ERROR = 'CONFIRM_USER_ERROR';
export const CONFIRM_USER_SUCCESS = 'CONFIRM_USER_SUCCESS';
export const INVITE_USER = 'INVITE_USER';
export const INVITE_USER_ERROR = 'INVITE_USER_ERROR';
export const CLICK_LOGOUT = 'CLICK_LOGOUT';
export const LOGOUT = 'LOGOUT';

// series actions
export const SERIES_ADD = 'SERIES_ADD';
export const SERIES_ADD_SUCCESS = 'SERIES_ADD_SUCCESS';
export const SERIES_ADD_ERROR = 'SERIES_ADD_ERROR';
export const SERIES_DELETE = 'SERIES_DELETE';
export const SERIES_SELECT = 'SERIES_SELECT';
export const SERIES_TOOGLE = 'SERIES_TOOGLE';
export const SERIES_APPLY_CODE = 'SERIES_APPLY_CODE';
watchChanges = [...watchChanges, SERIES_ADD_SUCCESS, SERIES_DELETE, SERIES_SELECT, SERIES_TOOGLE];

// rates series actions
export const RATES_SELECT = 'RATES_SELECT';
export const RATES_FETCH_SUCCESS = 'RATES_FETCH_SUCCESS';
export const RATES_FETCH_ERROR = 'RATES_FETCH_ERROR';

// portfolio actions
export const PORT_SELECT = 'PORT_SELECT';
export const PORT_UPDATE_ASSETS = 'PORT_UPDATE_ASSETS';
export const PORT_UPDATE_ASSETS_SIDE_EFFECTS = 'PORT_UPDATE_ASSETS_SIDE_EFFECTS';
export const PORT_UPDATE_CLASSES = 'PORT_UPDATE_CLASSES';
export const PORT_UPDATE_STRATS = 'PORT_UPDATE_STRATS';
export const PORT_SWITCH_STRATS = 'PORT_SWITCH_STRATS';
export const PORT_UPDATE_OPTIONS = 'PORT_UPDATE_OPTIONS';
export const PORT_NEW = 'PORT_NEW';
export const PORT_DELETE = 'PORT_DELETE';
export const PORT_SAVE = 'PORT_SAVE';
export const PORT_BACKTEST = 'PORT_BACKTEST';
export const PORT_BACKTEST_SUCCESS = 'PORT_BACKTEST_SUCCESS';
export const PORT_BACKTEST_ERROR = 'PORT_BACKTEST_ERROR';
export const PORT_ADD_PUBLIC = 'PORT_ADD_PUBLIC';
export const PORT_ADD_PUBLIC_SUCCESS = 'PORT_ADD_PUBLIC_SUCCESS';
export const PORT_ADD_PUBLIC_ERROR = 'PORT_ADD_PUBLIC_ERROR';

// backtests actions
export const BACKTEST_TOOGLE = 'BACKTEST_TOOGLE';
watchChanges = [...watchChanges, PORT_BACKTEST_SUCCESS, PORT_SELECT, PORT_NEW, PORT_DELETE, BACKTEST_TOOGLE];

// ui actions
export const UI_EXPLORE_RANGE = 'UI_EXPLORE_RANGE';
export const UI_BACKTEST_RANGE = 'UI_BACKTEST_RANGE';
export const UI_EXPLORE_OPTIONS = 'UI_EXPLORE_OPTIONS';
export const UI_PORT_SELECT_PILL = 'UI_PORT_SELECT_PILL';
export const UI_RESIZE = 'UI_RESIZE';
export const UI_EXPLORE_TYPE = 'UI_EXPLORE_TYPE';
export const UI_VISIBILITY_TOOGLE = 'UI_VISIBILITY_TOOGLE';
export const UI_SET_VAL_EXPLORE = 'UI_SET_VAL_EXPLORE';
export const UI_SET_VAL_OVERVIEW = 'UI_SET_VAL_OVERVIEW';
export const UI_SET_VAL_BACKTEST = 'UI_SET_VAL_BACKTEST';
export const UI_SET_VAL_RUN = 'UI_SET_VAL_RUN';
export const UI_SET_VAL_TABLE = 'UI_SET_VAL_TABLE';
export const UI_SHOW_ERROR = 'UI_SHOW_ERROR';
export const UI_HIDE_ERROR = 'UI_HIDE_ERROR';
export const SET_PROGRESS = 'SET_PROGRESS';

// params actions
export const PARAMS_LOAD = 'PARAMS_LOAD';
export const PARAMS_LOAD_SUCCESS = 'PARAMS_LOAD_SUCCESS';
export const PARAMS_LOAD_ERROR = 'PARAMS_LOAD_ERROR';
export const FETCH_STATE_SUCCESS = 'FETCH_STATE_SUCCESS';
export const FETCH_STATE_ERROR = 'FETCH_STATE_ERROR';
export const WAKEUP_SUCCESS = 'WAKEUP_SUCCESS';


// run actions
export const RUN_SAVE = 'RUN_SAVE';
export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'FETCH_TRANSACTIONS_ERROR';
export const FETCH_REPLAY = 'FETCH_REPLAY';
export const FETCH_REPLAY_SUCCESS = 'FETCH_REPLAY_SUCCESS';
export const FETCH_REPLAY_ERROR = 'FETCH_REPLAY_ERROR';
export const FETCH_POSITIONS = 'FETCH_POSITIONS';
export const FETCH_POSITIONS_SUCCESS = 'FETCH_POSITIONS_SUCCESS';
export const FETCH_POSITIONS_ERROR = 'FETCH_POSITIONS_ERROR';


watchChanges = [...watchChanges, RUN_SAVE];

// contact actions
export const CONTACT_SEND = 'CONTACT_SEND';
export const CONTACT_SEND_SUCCESS = 'CONTACT_SEND_SUCCESS';
export const CONTACT_SEND_ERROR = 'CONTACT_SEND_ERROR';
export const CONTACT_SUBSCRIBE = 'CONTACT_SUBSCRIBE';
export const CONTACT_SUBSCRIBE_SUCCESS = 'CONTACT_SUBSCRIBE_SUCCESS';
export const CONTACT_SUBSCRIBE_ERROR = 'CONTACT_SUBSCRIBE_ERROR';


// account page actions
export const USER_UPDATE_ATTRIBUTES = 'USER_UPDATE_ATTRIBUTES';

watchChanges = [...watchChanges, USER_UPDATE_ATTRIBUTES];


export {watchChanges};
