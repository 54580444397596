import React, { Component } from 'react';
import {Button, OverlayTrigger,
    Tooltip, Dropdown, ButtonGroup} from 'react-bootstrap';
import {FiRefreshCw} from 'react-icons/fi';

import {connect} from 'react-redux';

import './AsyncButton.css';

class AsyncButton extends Component {

    render() {
        
        let {children, working, workingText, disabled, forceReady,
            onClick, id, style, variant, dropup, tooltipPlacement,
            extraActions, disabledMessage, className, ready, dispatch, ...props} = this.props;

        disabled = disabled || (!ready && !forceReady);
        disabledMessage = ready || forceReady ? disabledMessage : 'The server is starting up';
        variant = variant ? variant : 'secondary';

        let button;

        if (extraActions) {
            let menu = extraActions.map(e => (
                <Dropdown.Item eventKey={e.eventKey}
                key={e.eventKey} 
                disabled={working || disabled}>{e.label}</Dropdown.Item>
            ));
            let actions = extraActions.reduce((a,e) => ({...a, [e.eventKey]:e.action}), {});
            button = (
                    <Dropdown
                    as={ButtonGroup}
                    id={id}
                    className={className}
                    drop={dropup ? 'up' : 'down'}
                    alignRight
                    onSelect={eventKey => actions[eventKey]()}>
                        <Button
                            {...props}
                            variant={variant}
                            disabled={working || disabled}
                            style={disabled ? {...(style||{}), pointerEvents:'none'} : (style||{})}
                            onClick={this.props.onClick || (()=>{})}>
                            {working && <FiRefreshCw className="fast-right-spinner" />}&nbsp;&nbsp;
                            {(working&&workingText) || children}
                        </Button>
                        <Dropdown.Toggle
                            variant={variant}
                            disabled={working || disabled} />
                        <Dropdown.Menu>
                            {menu}
                        </Dropdown.Menu>
                    </Dropdown>);

        } else {
            button = (<Button {...props}
                variant={variant}
                className={className}
                disabled={working || disabled}
                style={disabled ? {...(style||{}), pointerEvents:'none'} : (style||{})}
                onClick={this.props.onClick || (()=>{})}>
                {working && <FiRefreshCw className="fast-right-spinner" />}&nbsp;&nbsp;
                {(working&&workingText) || children}
            </Button>);
        }

        let tooltip = (
            <Tooltip id="tooltip_button">{disabledMessage}</Tooltip>
        );

        if (disabled && disabledMessage) {
            return (<OverlayTrigger placement={tooltipPlacement || 'right'} overlay={tooltip}>
                <div style={{display:'block',cursor:'not-allowed'}}>{button}</div>
            </OverlayTrigger>);
        } else {
            return button;
        }
    }

}

function mapStateToProps(state) {
    return {
        ready: state.lifecycle.serverReady
    };
}

export default connect(mapStateToProps)(AsyncButton);
