import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'reducers';
import rootSaga from 'sagas';
import { createLogger } from 'redux-logger';

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  let middlewares = [sagaMiddleware];
  if (process.env.REACT_APP_STAGE === "dev") {
    middlewares = [createLogger(), ...middlewares];
  }
  return {
    ...createStore(rootReducer,applyMiddleware(...middlewares)),
    runSaga: sagaMiddleware.run(rootSaga)
  };
};

export default configureStore;
