import * as types from 'actions/actionTypes';

// reducer for "run"
const defaultRun = {};
const runUI = (state = defaultRun, action) => {
  switch (action.type) {
    case types.UI_SET_VAL_RUN:
      if (action.payload.UIKey) {
        return {...state,[action.payload.UIKey]: action.payload.val};
      }
      return state;
    default:
      return state;
  }
};

export default runUI;
