import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Container} from 'react-bootstrap';
import './NotFound.css';

class NotFound extends Component {

  render() {
    return (
      <div className="NotFound">
        <Container>
          <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <h1>Page not found</h1>
              <p><strong>Sorry, the page you requested could not be found :(</strong></p>
              <p>From here you have some options :</p>
              <ul>
                <li>Go back to the <Link to="/">homepage</Link></li>
                <li>Check out our <a href="/docs/">docs</a> to find what you are looking for</li>
                <li>Reach us using the <Link to="/contact/">contact form</Link> if you have any question</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default NotFound;
