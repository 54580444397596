import {getToken} from 'api/auth';
import {retry} from './retry';

const superFetch = params => payload => new Promise(resolve => {
  let url = null;
  let req = {headers: {}};
  if (params.url) {
    if (typeof params.url === "string") {
      url = params.url;
    } else if (typeof params.url === "function"){
      url = params.url(payload);
    }
  }
  req.method = params.method ? params.method : "GET";
  if (payload && req.method !=="GET" && req.method !== "get") {
    req.body = JSON.stringify(payload);
    req.headers['Content-Type'] = 'application/json';
  }

  let authHeader = params.auth ? 
    getToken().then(token => {
      req.headers['Authorization'] = token;
      return req;
    }) : Promise.resolve(req);


  authHeader
  .then(req => retry(fetch, params.retry || 0, params.retryDelay || 2000)(url, req))
  .then(res => {
    if (res.ok) {
      return res.json().then(res2=>({err:false,payload:res2}));
    } else {
      return res.json().then(res2=>({err:res2,payload:null}));
    }
  })
  .catch(err => ({err,payload:null}))
  .then(res => {
    if (!res.err || !params.fallback) {
      return resolve(res);
    } else {
      retry(fetch, params.fallbackRetry || 0, params.fallbackRetryDelay || 2000)(params.fallback, req)
      .then(res3 => {
        if (res3.ok) {
          return res3.json().then(res2=>resolve({err:false,payload:res2}));
        } else {
          return res3.json().then(res2=>resolve({err:res2,payload:null}));
        }
      });
    }
  });
});

export default superFetch;
