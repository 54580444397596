import { createSelector } from 'reselect';
import {DataFrame} from 'dataframe-js';
import {createLoggedSelector} from 'modules/logger';

const getContracts = (state) => state.params.contracts;

const getContractsByCodeByTicker = createSelector(
  [getContracts],
  (contracts) => contracts ? contracts.reduce((a,e)=>{
    let mults = e.multiplier || 0;
    if (e.ticker && typeof mults !== "object"){
      mults = {[e.ticker]: mults};
    }
    e.indicators = ["long", "short", ...(e.indicator ? e.indicator.split(";") : [])];
    a.byCode = {...a.byCode, [e.genericCode]: {...e, multiplier: mults[e.ticker]}};
    if (mults) {
      let newTickers = Object.keys(mults).reduce((a,i)=>({...a, [i]: {...e, multiplier: mults[i]}}), {});
      a.byTicker = {...a.byTicker, ...newTickers};
    }
    return a;
  },{byCode: {}, byTicker: {}}) : {}
);

export const getContractsByCode = createSelector(
  [getContractsByCodeByTicker],
  (contracts) => {
    return contracts.byCode;
  });

export const getContractsByTicker = createSelector(
  [getContractsByCodeByTicker],
  (contracts) => {
    return contracts.byTicker});
    
export const getCodesByName = createSelector(
    [getContractsByCode],
    contractsByCode => 
        contractsByCode ? 
           Object.keys(contractsByCode).reduce((a, i) => ({...a, [contractsByCode[i].contractName]: i}), {}) : {});

export const getContractsByClass = createSelector(
  [ getContractsByCode ],
  ( contracts ) => {
    let classes = contracts ? Object.keys(contracts).reduce((a,i)=>{
      let e = contracts[i];
      if (!a[e.type]) {
        a[e.type] = [];
      }
      a[e.type].push({  code: e.genericCode,
                        label: e.genericCode + " - " + e.contractName,
                        indicators: ["long", "short", ...(e.indicator ? e.indicator.split(";") : [])]
                      });
      return a;
    }, {}) : {};
    return Object.keys(classes).map((i)=>({class: i, contracts: classes[i]}));
  }
);

const sortRollContracts = (rollContracts) => [
  'max_oi', '1m', '3m', '6m', '12m', '18m', '24m', 
  ...Array(30).fill().map((e, i) => '#' + (i+1))
].filter(e => rollContracts.includes(e));

export const getContractsDF = createLoggedSelector( "contractsDF",
  [getContracts],
  contracts => (contracts ? new DataFrame(contracts) :
    new DataFrame([],["contractName", "genericCode", "currency", "type", "indicator", "lastPrices"]))
    .withColumn('rollContract_selValues', (row) => (row.get('lastPrices') ? 
      sortRollContracts(Object.keys(row.get('lastPrices'))) : 
      []).join(';'))
    .sortBy('type')
);

export const getCodesList = createSelector(
  [getContracts],
  contracts => (contracts||[]).map(e=>e.genericCode)
);

export const getContractsNames = createSelector(
  [getContracts],
  contracts => (contracts||[])
    .sort((a, b) => a.type === b.type ?
      (a.contractName === b.contractName ? 0 : (a.contractName < b.contractName ? -1 : 1)) :
      (a.type < b.type ? -1 : 1)) 
    .map(e => e.contractName)
);

const getSeries = (state) => state.params.series;

export const getSeriesDesc = createSelector(
  [getSeries],
  (seriesDesc) => [{name: "Interest rates",
    series: seriesDesc.rates ? seriesDesc.rates.map(r => ({code: r.code, name: r.name})) : []},
    {name: "Foreign exchange rates",
      series: seriesDesc.fx ? seriesDesc.fx.map(r => ({code: r.code, name: r.name})) : []}]
);

export const getToken = state => state.auth && state.auth.jwt;
