import React, { Component } from 'react';
import {connect} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';
import {Helmet} from 'react-helmet';
import {Container, Col, Row, Card, Button, ButtonToolbar, CardDeck} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {FiCheckSquare, FiPercent, FiTerminal, FiZoomIn} from 'react-icons/fi';
import Loading from 'components/Loading';
import './Home.css';


class Home extends Component {

    render() {

        return (
            <div className="Home">
                <Helmet>
                    <title>Build and backtest futures contracts portfolios without coding | FuturesBacktest</title>
                    <meta name="description" content="Build and backtest futures contracts portfolios with 50+ contracts and ready-to-use trading strategies, without writing a single line of code." />
                </Helmet>
                <div className="lander">
                    <div className={'lander-ovr'}>
                        <Container>
                            <Row>
                                <Col lg={5}>
                                    <div className="title">
                                        <span>FUTURES BACKTEST</span>
                                        <div><h1>Build and backtest futures contracts portfolios with 50+ contracts and ready-to-use strategies</h1></div>
                                    </div>
                                </Col>
                                <Col lg={7} className="rightcol">
                                    {!this.props.loggedInUser &&
                                  <div className="subscribe">
                                      <p>Create a free account and start backtesting now!</p>
                                      <ButtonToolbar>
                                          <LinkContainer to="/signup/"><Button variant="secondary">Sign up</Button></LinkContainer>
                                          <Button href="#features" id="learn-more" variant="secondary">Learn more</Button></ButtonToolbar>
                                  </div>}
                                    {!this.props.doingLogin && this.props.loggedInUser &&
                                  <div className="subscribe">
                                      <h4>Welcome {this.props.loggedInUser}!</h4>
                                      <p>Check out our <a href="/docs/">docs</a> to get started.</p>
                                      <p>Don't hesitate to <Link to="/contact/">contact us</Link> if you need some help!</p>
                                  </div>}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <div className="features" id="features">
                    <Container>
                        <Row>
                            <Col>
                                <h4>We provide a web-based backtesting software to build and backtest futures contracts portfolios without writing a single line of code</h4>
                                <CardDeck>
                                    <Card className="zoom">
                                        <Card.Body>
                                            <Card.Title as="h3"><FiTerminal /> &nbsp; No coding required</Card.Title>
                                            <ul>
                                                <li>Combine assets classes, futures contracts and trading strategies through a <em>simple graphical interface</em>.</li>
                                                <li>Different flavours of <em>trend following, carry and value</em> strategies are available out of the box.</li>
                                                <li>Experiment with several backtesting options on daily data, <em>starting from the 60's</em> for some futures contracts.</li>
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                    <Card className="zoom">
                                        <Card.Body>
                                            <Card.Title as="h3"><FiZoomIn /> &nbsp; Transparency</Card.Title>
                                            <ul>
                                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                                <li>We <em>explain in our <a href="/docs/" target="_blank">doc</a></em> every calculation involved, so that you get reproducible results.</li>
                                                <li>We reference our choices regarding strategies and methodology from <em>published paper</em> from academics and practionners.</li>
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                    <Card className="zoom">
                                        <Card.Body>
                                            <Card.Title as="h3"><FiPercent /> &nbsp; Detailed analysis</Card.Title>
                                            <ul>
                                                <li>Backtesting results come with many <em>statistics and ratios</em> to help you compare simulated portfolios.</li>
                                                <li><em>Performance attribution analysis</em> shows the individual contribution of each asset class, asset or strategy to the total portfolio P&L.</li>
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </CardDeck>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="whatwedo">
                    <Container>
                        <Row>
                            <Col className="px-5 py-3 col-lg-6">
                                <h3 className="d-inline-block w-100 text-center my-4">What we do</h3>
                                <p>FuturesBacktest is a configurable online platform for <strong>building futures contracts portfolios without coding</strong>. You can choose from 50+ contracts for which we provide up-to-date daily prices with historic data dating back to the 60' for some contracts.</p>
                                <p>We let you to combine popular futures trading strategies such as <strong>trend following, carry trading or value</strong> applied across your investment universe. We provide also weighting and risk management methods rooted in academic literature on portfolio construction: <strong>inverse-volatility, mean-variance optimization, risk-parity</strong>…</p>
                                <p>Our backtesting engine rebalances the whole portfolio at given dates, which lowers the number of trades and <strong>makes it more manageable to track</strong>.</p>
                            </Col>
                            <Col className="px-5 py-3 col-lg-6">
                                <h3 className="d-inline-block w-100 text-center mt-4">News from our Blog</h3>
                                {this.props.blog && <div className="text-left">
                                    {this.props.blog.slice(0, 2).map(article => <div key={article.id}>
                                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                        <h4><a href={article.link} target="_blank">{article.title}</a></h4>
                                        <p className="mb-1" >{article.summary._}</p>
                                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                        <p><a href={article.link} target="_blank">READ MORE »</a></p>
                                    </div>)}
                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                    <a href="/blog/" target="_blank">MORE ARTICLES »</a>
                                </div>}
                                {!this.props.blog && <Loading />}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="pricing">
                    <Container>
                        <Row>
                            <Col>
                                <h2>Pricing</h2>
                                <CardDeck>
                                    <Card className="zoom">
                                        <Card.Header as="h3">Free membership</Card.Header>
                                        <Card.Body>
                                            <p>A good option to start experimenting with futures contracts trading strategies.</p>
                                            <p><FiCheckSquare /> &nbsp; backtest <em>5</em> futures contracts</p>
                                            <p><FiCheckSquare /> &nbsp; combine <em>trend following, carry and value</em> strategies for each contract</p>
                                            <p><FiCheckSquare /> &nbsp; save up to <em>5</em> portfolios</p>
                                        </Card.Body>
                                        <Card.Footer>FREE</Card.Footer>
                                    </Card>
                                    <Card className="zoom">
                                        <Card.Header as="h3">Premium membership</Card.Header>
                                        <Card.Body>
                                            <p>Our most complete option: access all contracts universe and all features.</p>
                                            <p><FiCheckSquare /> &nbsp; backtest <em>50+</em> futures contracts</p>
                                            <p><FiCheckSquare /> &nbsp; combine <em>trend following, carry and value</em> strategies for each contract</p>
                                            <p><FiCheckSquare /> &nbsp; <em>unlimited</em> portfolios</p>
                                        </Card.Body>
                                        <Card.Footer>FREE while in beta*</Card.Footer>
                                    </Card>
                                </CardDeck>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <LinkContainer to="/signup/"><Button variant="primary">Sign up now for a free account</Button></LinkContainer>
                                <p className="nota">* 'Beta' stage means that we are still developping the platform and that you may encounter a few bugs or missing features.</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        doingLogin: state.lifecycle.doingLogin,
        loggedInUser: (state.user && state.user.attributes && state.user.attributes.name) || false,
        blog: state.params.blog && state.params.blog.items.slice(0, 2)
    };
}

export default connect(mapStateToProps)(Home);
