
import superFetch from 'modules/superFetch';

export const getRates = superFetch({
  url: codes => ('/api/data/rates.json?codes=' + codes.join(';'))
})

const queryString = (data, args=null) => {
  let params = data;
  if (args) {
    params = args.map(e => ({key: e, data: data[e]}))
      .filter(e => e.data !== undefined)
      .reduce((a,e)=>({...a, [e.key]:encodeURIComponent(e.data)}),{});
  }
  return Object.keys(params)
    .map(e => e + "=" +
      (Array.isArray(params[e]) ? "[" + params[e].join(",") + "]" : params[e]))
    .join("&");
}

export const getSeries = superFetch({
  url: payload => ('/api/data/series.json?' +
    queryString(payload, ['genericCode', 'rollContract', 'rolled', 'strat', 'stratParams',
      'rebalance', 'rebalanceDate', 'rebalanceRule', 'weighting', 'leverage', 'lookback'])),
  auth: true
});

// start backtest request
export const startBacktest = superFetch({
  url: '/api/data/backtest.json',
  method: 'POST',
  auth: true
});
// try fetching job
export const fetchJob = superFetch({
  url: reqId => ('/api/jobs/' + reqId)
});

// get current positions
export const getPositions = superFetch({
  url: '/api/data/positions.json',
  method: 'POST',
  auth: true
});
