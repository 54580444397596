import { takeLatest, takeEvery, call, select, put, delay, all } from 'redux-saga/effects';
import * as types from 'actions/actionTypes';
import {fetchStateSuccess, fetchStateError} from 'actions/paramsActions';
import {portBacktestSuccess} from 'actions/portfoliosActions';
import {saveState, fetchState} from 'api/state';
import {fetchJob} from 'api/futures';
import {seriesAdd} from 'actions/seriesActions';

const stateFetchStatus = state => (state.params.stateFetchStatus);

const makePersistPath = (path, delayms=5000) => {
    const selector = state => {
        if (path) {
            return path.split('.').reduce((a,e)=>a[e], state);
        } else {
            return state;
        }
    };
    function* persistPath() {
        yield delay(delayms);
        if (yield select(stateFetchStatus)) {
            const data = yield select(selector);
            yield call(saveState, {path, data});
        }
    }
    return persistPath;
};

const persistUiExplore = makePersistPath('ui.explore', 5000);
const persistUiBacktest = makePersistPath('ui.backtest', 5000);
const persistUiOverview = makePersistPath('ui.overview', 5000);
const persistUiTables = makePersistPath('ui.tables', 5000);
const persistTransac = makePersistPath('transactions', 2000);
const persistUser = makePersistPath('user.attributes', 2000);

const getPortfolio = (state, id) => state.portfolios && state.portfolios[id];

function* persistPortfolio(action) {
    if (yield select(stateFetchStatus)) {
        if (action.payload && action.payload.id) {
            if (action.payload.data && action.payload.data.portfolio) {
                if (!action.payload.data.portfolio.readonly) {
                    let port = yield select(getPortfolio, action.payload.id);
                    yield call(saveState, {path: 'portfolios.'+action.payload.id, data:port});
                }
            } else {
                yield call(saveState, {path: 'portfolios.'+action.payload.id});
            }
        }
    }
}

function* postStateFetch(action) {
    if (action && action.payload && action.payload.portfolios &&
    action.payload.ui && action.payload.ui.backtest && action.payload.ui.backtest.overviewVis &&
    action.payload.ui.backtest.overviewVis.selPortfolios) {
        let selPorts = action.payload.ui.backtest.overviewVis.selPortfolios;
        let ports = action.payload.portfolios;
        let toFetch = Object.keys(ports)
            .filter(id => (selPorts.indexOf(id) >= 0) && ports[id]['jobId']);
        let quer = yield all(toFetch.map(id => call(fetchJob, ports[id]['jobId'])));
        for (let i=0; i<toFetch.length; i++) {
            if (quer[i] && quer[i].payload && !quer[i].err) {
                yield put(portBacktestSuccess({
                    id:toFetch[i], 
                    name:ports[toFetch[i]].name, 
                    data:quer[i].payload
                }));
            }
        }
    }
}

export function* handleSaveState() {
    yield takeLatest(types.UI_SET_VAL_EXPLORE, persistUiExplore);
    yield takeLatest([types.UI_SET_VAL_BACKTEST, types.PORT_NEW,
        types.PORT_DELETE, types.PORT_BACKTEST_SUCCESS], persistUiBacktest);
    yield takeLatest(types.UI_SET_VAL_OVERVIEW, persistUiOverview);
    yield takeLatest(types.UI_SET_VAL_TABLE, persistUiTables);
    yield takeLatest([types.FETCH_TRANSACTIONS_SUCCESS, types.RUN_SAVE], persistTransac);
    yield takeEvery(types.PORT_DELETE, persistPortfolio);
    yield takeEvery(types.PORT_BACKTEST_SUCCESS, persistPortfolio);
    yield takeEvery(types.PORT_SAVE, persistPortfolio);
    yield takeLatest(types.FETCH_STATE_SUCCESS, postStateFetch);
    yield takeLatest(types.USER_UPDATE_ATTRIBUTES, persistUser);
}

function* doFetchState(action) {
    const { payload, err } = yield call(fetchState, {}, action.payload.jwt);
    if (payload && !err) {
        let newState = payload;
        if (newState && newState.series && newState.series.byId) {
            for (let i in newState.series.byId) {
                yield put(seriesAdd({...newState.series.byId[i]}));
            }
        }
        yield put(fetchStateSuccess(payload));
    } else {
        yield put(fetchStateError(err));
    }
}

export function* handleFetchState() {
    yield takeLatest(types.LOGIN_SUCCESS, doFetchState);
}
