import * as types from 'actions/actionTypes';
import * as uiKeys from 'actions/uiKeys';

// reducer for "backtest" view
const defaultBacktest = {
  [uiKeys.PORT_SIZE]: 380,
  [uiKeys.SEL_PORT]: "port1"
};

const unique = (arr) => arr.filter((e, i) => arr.indexOf(e) === i);

const backtestUI = (state = defaultBacktest, action) => {
  switch (action.type) {
    case types.UI_SET_VAL_BACKTEST:
      if (action.payload.UIKey) {
        return {...state, [action.payload.UIKey]: action.payload.val};
      }
      return state;

    case types.PORT_NEW:
      return {...state, [uiKeys.SEL_PORT]: action.payload.id};
    case types.PORT_ADD_PUBLIC_SUCCESS:
      return {...state, [uiKeys.SEL_PORT]: action.payload.id};

    case types.PORT_DELETE:
      return {...state, [uiKeys.SEL_PORT]: action.payload.lastId};

    case types.PORT_BACKTEST_SUCCESS:
      let overviewVis = state[uiKeys.OVERVIEW_VIS] || {};
      return {
        ...state,
        [uiKeys.OVERVIEW_VIS]: {
          ...overviewVis,
          selPortfolios: unique([...(overviewVis.selPortfolios || []), action.payload.id])
        },
        [uiKeys.CONTRIB_SEL]:  action.payload.id,
        [uiKeys.POSITIONS_SEL]: {selBacktest: action.payload.id}
      };
    default:
      return state;
  }
};

export default backtestUI;
