import config from 'config/cognito';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';

const configureCookie = (cookie = true) => {
    Amplify.configure({
        Auth: {
            region: 'eu-central-1',
            userPoolId: config.USER_POOL_ID,
            userPoolWebClientId: config.CLIENT_ID,
            cookieStorage: process.env.REACT_APP_SERVER === 'local' ? undefined : {
                domain: '.futuresbacktest.com',
                expires: cookie ? 365 : undefined
            },
            oauth: {
                domain: 'authuser.futuresbacktest.com',
                redirectSignIn: window.location.origin,
                redirectSignOut: window.location.origin + '/login/',
                scope: ['email', 'profile', 'openid'],
                responseType: 'code',
                url: window.location.href
            }
        }
    });
};

configureCookie(false);

export const getCurrentUser = () => Auth.currentUserInfo()
    .then(user => ({user: user.attributes}));

export const getToken = () => Auth.currentSession()
    .then(data => data.idToken.jwtToken);

export const cognitoSignIn = params => {
    const { email, password, newPassword, cookie } = params;
    configureCookie(!!cookie);
    return Auth.signIn(email, password)
        .then(user => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                return Auth.completeNewPassword(user, newPassword, {email});
            } else {
                return user;
            } 
        })
        .then(user => user && user.attributes);
};

export const signOut = (global=false) => Auth.signOut(global ? { global: true } : undefined);

export const cognitoSignup = params => {
    const { email, name, password } = params;
    return Auth.signUp({
        username: email,
        password,
        attributes: {
            email,
            name
        }});
};


// confirm a registered user
export const cognitoConfirm = (username, confirmKey) => Auth.confirmSignUp(username, confirmKey);

export const cognitoResetPassword = email => Auth.forgotPassword(email);

export const cognitoNewPassword = (email, newPassword, verificationCode) => Auth.forgotPasswordSubmit(email, verificationCode, newPassword);

export const federatedSignin = (options) => Auth.federatedSignIn(options);
