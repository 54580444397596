
import superFetch from 'modules/superFetch';

export const sendMessage = superFetch({
  url: '/api/service/send',
  method: "POST"
})

export const subscribeEmail = superFetch({
  url: payload => '/api/service/subscribe?email=' + payload.email
})
