import React, { Component } from 'react';
import {
    Form, Button,
    Container, Col, Row
} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';
import {Link} from 'react-router-dom';
import {signup, clickLogout} from 'actions/authActions';
import {federatedSignin} from 'api/auth';
import AsyncButton from 'components/AsyncButton';
import {IconGoogle, IconFacebook} from 'components/icons';

import './Login.css';

class Signup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            password: '',
            password2: ''
        };
    }

    handleSignup(e) {
        e.preventDefault();
        const { email, name, password } = this.state;
        this.props.dispatch(signup({ email, name, password }));
    }

    handleChange(column, value) {
        this.setState({[column]: value});
    }

    componentDidMount(){
        setTimeout(()=>{
            const {auth} = this.props;
            if (auth && auth.isLoggedIn) {
                this.props.dispatch(clickLogout());
            }
        },500);
    }

    validator() {
        var re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.state.email || !this.state.password) {
            return {disabled: true, message: 'Email or password is missing'};
        } else if(this.state.password !== this.state.password2) {
            return {disabled: true, message: 'Passwords don\'t match'};
        } else if(!re.test(this.state.email)) {
            return {disabled: true, message: 'Email is invalid'};
        } else if(this.state.password.length<6) {
            return {disabled: true, message: 'Password must be at least 6 characters long'};
        } else {
            return {disabled: false, message: null};
        }
    }

    dismissAlert() {
        this.setState({toRedirect: true});
    }

    render() {
        const { auth } = this.props;
        const { email, name, password, password2 } = this.state;

        if (this.state.toRedirect) {
            return <Redirect to="/" />;
        }

        if (auth.status === 'SIGNED_UP') {
            return (
                <div className="Login">
                    <Helmet>
                        <title>Sign up for a free account | FuturesBacktest</title>
                        <meta name="description" content="Sign up now for a free account and start building your futures portfolio" />
                    </Helmet>
                    <Container className="lander">
                        <Row>
                            <Col md={12}>
                                <h2 style={{marginBottom:'1em', width:'100%', display:'block', textAlign:'center'}}>You're almost there...</h2>
                                <p>We need to verify your email address to validate your account.</p>
                                <p>Please check your emails and click on the activation link we sent you.</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }

        const {disabled, message} = this.validator();

        return (
            <div className="Login">
                <Helmet>
                    <title>Sign up for a free account | FuturesBacktest</title>
                    <meta name="description" content="Sign up now for a free account and start building your futures portfolio" />
                </Helmet>
                <Container className="lander">
                    <Row>
                        <Col md={12}>
                            <h2 style={{marginBottom:'1em', width:'100%', display:'block', textAlign:'center'}}>Hello there!</h2>
                            <p style={{marginBottom: '1em'}}>Sign up for free and start backtesting.</p>
                            <form onSubmit={this.handleSignup.bind(this)}>
                                <Form.Group
                                    controlId="email">
                                    <Form.Control
                                        type="email"
                                        placeholder="Your email"
                                        value={email}
                                        onChange={(e) => this.handleChange('email', e.target.value)} />
                                </Form.Group>
                                <Form.Group
                                    controlId="name">
                                    <Form.Control
                                        type="text"
                                        placeholder="Your name"
                                        value={name}
                                        onChange={(e) => this.handleChange('name', e.target.value)} />
                                </Form.Group>
                                <Form.Group
                                    controlId="password">
                                    <Form.Control
                                        type="password"
                                        value={password}
                                        placeholder="Your password"
                                        onChange={(e) => this.handleChange('password', e.target.value)} />
                                    <Form.Control.Feedback />
                                </Form.Group>
                                <Form.Group
                                    controlId="password2">
                                    <Form.Control
                                        type="password"
                                        placeholder="Repeat your password"
                                        value={password2}
                                        onChange={(e) => this.handleChange('password2', e.target.value)} />
                                </Form.Group>
                                <Form.Group>
                                    <AsyncButton
                                        type="submit"
                                        bs="primary"
                                        working={this.props.doingSignup}
                                        disabled={disabled}
                                        disabledMessage={message}
                                        block
                                        workingText="Signing up...">Sign up</AsyncButton>
                                </Form.Group>
                            </form>
                            <p style={{textAlign:'right'}}>Already have an account? <Link to="/login/">Log in</Link></p>
                            <div className="hl-or"><span>OR</span></div>
                            <Button variant="outline-light" className="btn-google" onClick={() => federatedSignin({provider: 'Google'})}>
                                <IconGoogle />Sign up with Google
                            </Button>
                            <Button variant="outline-light" className="btn-facebook" onClick={() => federatedSignin({provider: 'Facebook'})}>
                                <IconFacebook />Continue with Facebook
                            </Button>
                            <p className="social-warning">We will not post anything on your behalf</p>
                        </Col>
                    </Row>
                </Container>
          
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        auth: state.auth,
        doingSignup: state.lifecycle.doingSignup
    };
}

export default connect(mapStateToProps)(Signup);
