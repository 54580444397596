import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as types from 'actions/actionTypes';
import {getRates} from 'api/futures';
import {ratesFetchSuccess, ratesFetchError} from 'actions/ratesActions';
import * as Nprogress from 'nprogress';

const getRatesData = state => state.data.rates;

var ratesCache = {};

function* fetchRates(action) {
  let rates = yield select(getRatesData);
  let toFetch = Object.keys(rates).filter(e => rates[e].data.length===0);
  let cached = toFetch.map(e => ratesCache[e]).filter(e => !!e);
  let fetched = [];
  toFetch = toFetch.filter(e => !ratesCache[e]);
  if (toFetch.length>0) {
    Nprogress.configure({ trickleRate: 0.1, trickleSpeed: 200 });
    Nprogress.start();
    const {payload, err} = yield call(getRates, toFetch);
    if (payload && !err) {
      fetched = payload;
      ratesCache = fetched.reduce((a,e) => ({
        ...a,
        [e.code]: e
      }), ratesCache);
    } else {
      yield put(ratesFetchError({err}));
    }
    yield call(Nprogress.done);
  }
  if (fetched.length>0 || cached.length>0) {
    yield put(ratesFetchSuccess([...fetched, ...cached]));
  }
}

export function* handleRatesFetch() {
  yield takeEvery(types.RATES_SELECT, fetchRates);
}
