import * as types from './actionTypes';
import {createAction} from 'redux-actions';

export const contactSend = createAction(types.CONTACT_SEND);
export const contactSendSuccess = createAction(types.CONTACT_SEND_SUCCESS);
export const contactSendError = createAction(types.CONTACT_SEND_ERROR);

export const contactSubscribe = createAction(types.CONTACT_SUBSCRIBE);
export const contactSubscribeSuccess = createAction(types.CONTACT_SUBSCRIBE_SUCCESS);
export const contactSubscribeError = createAction(types.CONTACT_SUBSCRIBE_ERROR);
