import shortid from 'shortid';
import mergeWith from 'lodash.mergewith';

import * as types from 'actions/actionTypes';
import {emptyPort, benchmarkPort} from './defaultPortfolios';
import DataFrame, {Row} from 'dataframe-js';
import {assetsColsState, assetsColsTable, stratsAssetsColsState, 
    stratsClassesColsState, classesColsState} from 'config/tables';
import {logger} from 'modules/logger';

const firstId = shortid.generate();

const formatDF = (df) => df ? ({
    columns: df.listColumns(),
    data: df.toArray()
}) : ({});

function addMissingClasses(assetsClasses, assets, contractsByCode) {
  let tmp2, missing, tmp = assetsClasses.unique('assetClass').toArray('assetClass');
  missing = assets.find(row => (tmp2=row.get('genericCode')) &&
                                (tmp2=contractsByCode[tmp2]) &&
                                (tmp2=tmp2.type) &&
                                (tmp.indexOf(tmp2)<0));
  if (missing) {
    assetsClasses = assetsClasses.push(new Row({
      assetClass: contractsByCode[missing.get('genericCode')].type,
      classWeight: 1
    }));
  }
  return assetsClasses;
}

function replace(array, from, to) {
    let idx = array.indexOf(from);
    if (idx >= 0) {
        array[idx] = to;
    }
    return array;
}

// reducer for one portfolio
const portfolio = (state = benchmarkPort(firstId), action) => {
  let pl = action.payload;
  let assets, assetsClasses, strats, tmp, toAdd, stratsAssetsMode;

  logger("start portfolio reducer");
  switch (action.type) {

    case types.PORT_UPDATE_CLASSES:
      assetsClasses = (new DataFrame(pl.data.data, pl.data.columns))
        .restructure(classesColsState)
        .chain(
            row => row.set('classWeight',
                    (tmp=row.get('classWeight')) || (tmp === 0 ? 0 : 1))
        );
      return {...state, assetsClasses: formatDF(assetsClasses.select(...classesColsState))};

    case types.PORT_UPDATE_ASSETS:
      assets = (new DataFrame(pl.data.data, pl.data.columns)).restructure(assetsColsTable);
      if (pl.contractsByCode && pl.contractsByTicker && pl.codesByName) {

        // complete rows
        assets = assets
        .map(row => row.set('genericCode',
          pl.codesByName[row.get('contractName')] || (((tmp=row.get('ticker')) && (tmp=pl.contractsByTicker[tmp]) && tmp.genericCode) || null)))
        .chain(
          row => row.set('ticker',
            row.get('ticker') || (((tmp=row.get('genericCode')) && (tmp=pl.contractsByCode[tmp]) && tmp.ticker) || null)),
          row => row.set('multiplier',
            row.get('multiplier') || (((tmp=row.get('genericCode')) && (tmp=pl.contractsByCode[tmp]) && tmp.multiplier) || null)),
          row => row.set('rollContract',
            row.get('rollContract') || (((tmp=row.get('genericCode')) && (tmp=pl.contractsByCode[tmp]) && tmp.rollContract) || null)),
          row => row.set('assetWeight',
            (tmp=row.get('assetWeight')) || (tmp === 0 ? 0 : 1))
        );
        
        return {...state,
            assets: formatDF(assets.select(...assetsColsState))
        };
    }
    break;
    
    case types.PORT_UPDATE_ASSETS_SIDE_EFFECTS:
      assets = (new DataFrame(pl.data.data, pl.data.columns)).restructure(assetsColsTable);
      if (pl.contractsByCode && pl.contractsByTicker && pl.codesByName) {

        // complete rows
        assets = assets
        .map(row => row.set('genericCode',
          pl.codesByName[row.get('contractName')] || (((tmp=row.get('ticker')) && (tmp=pl.contractsByTicker[tmp]) && tmp.genericCode) || null)));

          // add missing strats
          if (state.strats && state.strats.data && state.strats.columns) {
            strats = new DataFrame(state.strats.data, state.strats.columns);
          } else {
            strats = new DataFrame([], ['ticker', 'rollContract', 'strat', 'stratWeight']);
          }
          //if strats in "assets" mode
          if (strats.listColumns().indexOf('ticker') >= 0) {
            strats = strats.restructure(stratsAssetsColsState);
            toAdd = assets
              .select('ticker', 'rollContract')
              .dropDuplicates()
              .withColumn('asset_id', (r,i) => i+1);
            strats = strats
              .withColumn('strat_id', (r, i) => i+1)
              .fullJoin(toAdd, ['ticker', 'rollContract'])
              .chain(
                r => r.set('strat', r.get('strat_id') ? r.get('strat') : 'long'),
                r => r.set('stratWeight', r.get('strat_id') ? r.get('stratWeight') : 1),
                r => r.set('strat_id', r.get('strat_id') || r.get('asset_id')+10000))
              .sortBy('strat_id');
          // else
          } else {
              strats = strats.restructure(stratsClassesColsState);
            toAdd = assets
              .select('assetClass')
              .dropDuplicates()
              .withColumn('class_id', (r,i) => i+1);
            strats = strats
              .withColumn('strat_id', (r, i) => i+1)
              .fullJoin(toAdd, 'assetClass')
              .chain(
                r => r.set('strat', r.get('strat_id') ? r.get('strat') : 'long'),
                r => r.set('stratWeight', r.get('strat_id') ? r.get('stratWeight') : 1),
                r => r.set('strat_id', r.get('strat_id') || r.get('class_id')+10000))
              .sortBy('strat_id');
          }

          // add missing assets class
          if (state.assetsClasses && state.assetsClasses.data && state.assetsClasses.columns) {
            assetsClasses = (new DataFrame(state.assetsClasses.data, state.assetsClasses.columns))
                .restructure(classesColsState);
          } else {
            assetsClasses = new DataFrame([], classesColsState);
          }
          toAdd = assets
            .filter(r => r.get('genericCode') && pl.contractsByCode[r.get('genericCode')])
            .withColumn('assetClass', r => pl.contractsByCode[r.get('genericCode')].type)
            .withColumn('classWeight', () => 1)
            .restructure(classesColsState)
            .dropDuplicates();
            
          assetsClasses = assetsClasses
            .union(toAdd)
            .dropDuplicates("assetClass");

          stratsAssetsMode = strats.listColumns().indexOf("ticker") >= 0;

          return {...state,
            assetsClasses: formatDF(assetsClasses.select(...classesColsState)),
            strats: formatDF(strats.select(...(stratsAssetsMode ? stratsAssetsColsState : stratsClassesColsState)))
          };
      }
      break;

    case types.PORT_UPDATE_STRATS:
      strats = new DataFrame(pl.data.data, pl.data.columns);
      assets = new DataFrame(state.assets.data, state.assets.columns);
      stratsAssetsMode = strats.listColumns().indexOf("ticker") >= 0;
      if (stratsAssetsMode) {
        strats = strats
        .restructure(stratsAssetsColsState)
        .chain(
          row => row.set('rollContract',
            row.get('rollContract') || ((tmp=assets.find(arow => arow.get('ticker') === row.get('ticker'))) && tmp.get('rollContract'))) || 1,
          row => row.set('stratWeight',
            (tmp=row.get('stratWeight')) || (tmp === 0 ? 0 : 1)),
          row => row.set('strat',
            row.get('strat') || 'long')
        );
      } else {
        strats = strats
        .restructure(stratsClassesColsState)
        .chain(
          row => row.set('stratWeight',
            (tmp=row.get('stratWeight')) || (tmp === 0 ? 0 : 1)),
          row => row.set('strat',
            row.get('strat') || 'long')
        );
      }
      if (pl.contractsByCode && pl.contractsByTicker) {
        assetsClasses = new DataFrame(state.assetsClasses.data, state.assetsClasses.columns);
        assetsClasses = assetsClasses.restructure(classesColsState);
        assetsClasses = addMissingClasses(assetsClasses, assets, pl.contractsByCode);
      }

      return {...state,
        assets: formatDF(assets.select(...assetsColsState)),
        assetsClasses: formatDF(assetsClasses.select(...classesColsState)),
        strats: formatDF(strats.select(...(stratsAssetsMode ? stratsAssetsColsState : stratsClassesColsState)))
      };

    case types.PORT_SWITCH_STRATS:
      stratsAssetsMode = state.strats.columns.indexOf("ticker") >= 0 ? 'ASSETS' : 'CLASSES';
      if (stratsAssetsMode === pl.mode) {
        return state;
      } else {
        strats = new DataFrame(state.strats.data, state.strats.columns);
        assets = (new DataFrame(state.assets.data, state.assets.columns))
            .restructure(assetsColsState);
        if (pl.mode === "ASSETS") {
          assets = assets.withColumn("assetClass", row => pl.contractsByCode[row.get("genericCode")].type);
          strats = strats
            .restructure(stratsClassesColsState)
            .leftJoin(assets.select("ticker", "rollContract", "assetClass"), "assetClass");
          return {...state,
            strats: formatDF(strats.select(...stratsAssetsColsState))
          };
        } else {
          strats = strats
            .restructure(stratsAssetsColsState)
            .leftJoin(assets.select("ticker", "rollContract", "genericCode"), ["ticker", "rollContract"])
            .withColumn("assetClass", row => pl.contractsByCode[row.get("genericCode")].type)
            .dropDuplicates("assetClass", "strat", "stratParams");
          return {...state,
            strats: formatDF(strats.select(...stratsClassesColsState))
          };
        }
      }


    case types.PORT_UPDATE_OPTIONS:
      logger(JSON.parse(JSON.stringify(pl)));
      return mergeWith(state, pl, (obj, src) => Array.isArray(src) ? src : undefined);

    case types.PORT_NEW:
      switch (action.payload.type) {
        case "empty":
          return {...emptyPort,
            id: action.payload.id, name: action.payload.name};
        case "bench":
          return {...benchmarkPort(action.payload.id),
            id: action.payload.id, name: action.payload.name};
        default:
          return {
            ...JSON.parse(JSON.stringify(state)),
            id: action.payload.id, name: action.payload.name, readonly: undefined, public: false};
      }

    default:
      return state;
  }
  return state;
};

// reducer for portfolios
const portfoliosById = (state = {[firstId]: portfolio(undefined, {})}, action) => {
  switch (action.type) {

    case types.PORT_UPDATE_ASSETS:
    case types.PORT_UPDATE_ASSETS_SIDE_EFFECTS:
    case types.PORT_UPDATE_STRATS:
    case types.PORT_SWITCH_STRATS:
    case types.PORT_UPDATE_CLASSES:
    case types.PORT_UPDATE_OPTIONS:
      return {...state, [action.payload.id]: portfolio(state[action.payload.id], action)};

    case types.PORT_NEW:
      return {...state, [action.payload.id]: portfolio(state[action.payload.from], action)};

    case types.PORT_ADD_PUBLIC_SUCCESS:
      return {...state, [action.payload.id]: action.payload};

    case types.PORT_DELETE:
      let {[action.payload.id]: _rm , ...newState} = {...state};
      return newState;

    case types.PORT_BACKTEST_SUCCESS:
      if (action.payload && action.payload.id && action.payload.data && action.payload.data.portfolio) {
        let jobId = action.payload.data.portfolio.jobId || action.payload.data.jobId;
        action.payload.data.portfolio.assets.columns = replace(action.payload.data.portfolio.assets.columns, 'rollContract', 'rollContract');
        action.payload.data.portfolio.strats.columns = replace(action.payload.data.portfolio.strats.columns, 'rollContract', 'rollContract');
        action.payload.data.portfolio.assetsClasses.columns = replace(action.payload.data.portfolio.assetsClasses.columns, 'rollContract', 'rollContract');
        return {...state,
          [action.payload.id]: {
            id: action.payload.id,
            name: action.payload.name,
            ...action.payload.data.portfolio,
            jobId,
            public: !!(state[action.payload.id] && state[action.payload.id].public)
          }
        };
      }
      return state;
    default:
      return state;
  }
};

export default portfoliosById;
