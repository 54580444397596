import * as types from 'actions/actionTypes';

const initState = {
  futures: false,
  deposits: false,
  fx: false,
  ibInfo: {}
}

/*const ddmmyyyy  = ts => {
  if (ts) {
    ts = new Date(ts*1000);
    var mm = ts.getMonth() + 1;
    var dd = ts.getDate();
    return [
            ts.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd
          ].join('-');
  }
};*/

// reducer for run data
export const transactions = (state = initState, action) => {
  let pl = action.payload;
  switch (action.type) {
    case types.RUN_SAVE:
      if (pl.type === "ibInfo") {
        return {...state, [pl.type]: pl.data};
      } else if (pl.type && ["futures", "deposits", "fx"].indexOf(pl.type)>=0) {
        let data = ((pl.data&&pl.data.data)||[]).map(e=>{
          if (Array.isArray(e)) return e;
          let res=[];
          for (let i in e) {
            res[i] = e[i];
          }
          return res;
        });
        return {...state, [pl.type]: {columns: pl.data.columns, data: data}};
      }
      break;
    case types.FETCH_TRANSACTIONS_SUCCESS:
      let {futures, fx} = state;
      if (pl.futures && pl.futures.data && pl.futures.columns) {
        futures = pl.futures;
      }
      if (pl.fx && pl.fx.data && pl.fx.columns) {
        fx = pl.fx;
      }
      return {...state, futures, fx};
    default:
      return state;
  }
}

export const replay = (state = {}, action) => {
  let pl = action.payload;
  switch (action.type) {
    case types.FETCH_REPLAY_SUCCESS:
      if (pl.balance) {
        let replay = {
          id: "balance",
          name: "balance",
          data: pl.balance.data.map(e=>([e[0]*1000,e[1]]))};
        return {"balance":replay};
      }
      return state;
    default:
      return state;
  }
}

export const positions = (state = {}, action) => {
  let pl = action.payload;
  switch (action.type) {
    case types.FETCH_POSITIONS_SUCCESS:
      if (pl.positions) {
        return {...pl.positions};
      }
      return state;
    default:
      return state;
  }
}
