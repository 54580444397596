import { take, call, put, select, delay } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {fetchJob} from 'api/futures';
import {setProgress} from 'actions/paramsActions';
import {uiShowError} from 'actions/uiActions';
import Notifications from 'modules/notifications';

const getProgress = state => state.lifecycle.progress;
const getToken = state => (state.auth.jwt);

export function* asyncFetch(apiCall, data={},
    options={
        initMessage: "Initializing",
        initPercentFrom: 0,
        initPercentTo: 10,
        initDuration: 2000,
        asyncFallbackDelay: 1000*60*3,
        silent: false
    }) {

    let notify, notifyChannel, status, lastTick = false, silent = options.silent;

    if (!silent) {
        let progress = yield select(getProgress);
        if (progress) {
            return {payload: null, err: "Another request is running"};
        }
        yield put(setProgress({
            message: options.initMessage,
            percentFrom: options.initPercentFrom,
            percentTo: options.initPercentTo,
            duration: options.initDuration
        }));
    }

    const token = yield select(getToken);

    const { payload: payload1, err: err1 } = yield call(apiCall, data, token);

    if (payload1 && !err1) {

        if (payload1.reqId) { // Async scenario

            if (payload1.notifications && !payload1.done) {

                notifyChannel = eventChannel(emitter => {

                    notify = new Notifications(emitter);
                    notify.connect(payload1.notifications);

                    const iv = setInterval(() => emitter({tick: true}), 1000*60*3); // send a tick every 3 minutes
                    return () => clearInterval(iv);
                });

                try {
                    while (true) { // wait for events
                        status = yield take(notifyChannel);
                        if (!status || status.error || (lastTick && status.tick)) {
                            break;
                        } else {
                            if (!silent) {
                                yield put(setProgress(status));
                            }
                            lastTick = !!status.tick;
                            if (status.done) {
                                break;
                            }
                        }
                    }
                } finally {
                    notifyChannel.close();
                }
            } else { // no socket connection used ; wait and try to fetch results
                yield delay(5*1000);
                status = true;
            }

            if (!status || status.error) {
                if (!silent) {
                    yield put(setProgress(null));
                    yield put(uiShowError());
                }
                return {payload:null, err: status.message};

            } else {
                let {payload: payload2, err: err2} = yield call(fetchJob, payload1.reqId);
                if (!silent) {
                    yield put(setProgress(null));
                    if (err2) {
                        yield put(uiShowError());
                    }
                }
                return {payload: {...payload2, jobId: payload1.reqId}, err: err2};
            }

        } else { // Sync scenario
            if (!silent) {
                yield put(setProgress(null));
            }
            return {payload: payload1, err: err1};
        }

    } else {
        if (!silent) {
            yield put(setProgress(null));
            yield put(uiShowError());
        }
        return {payload:null, err1};
    }
}
