import { createSelector } from 'reselect';
import {createLoggedSelector, logger} from 'modules/logger';
import {getContractsDF} from 'selectors/params';
import * as uiKeys from 'actions/uiKeys';
import DataFrame from 'dataframe-js';
import {assetsColsTable, assetsColsState,
    stratsAssetsColsTable, stratsClassesColsTable, 
    classesColsTable, classesColsState} from 'config/tables';


const formatDF = (df) => df && df.listColumns ? {
    columns: df.listColumns(),
    data: df.toArray()
}: {};

const getPortById = (state) => state.portfolios;

export const getSelectedPortId = (state) => {
    if (state.portfolios) {
        if (state.ui.backtest[uiKeys.SEL_PORT] && state.portfolios[state.ui.backtest[uiKeys.SEL_PORT]]) {
            return state.ui.backtest[uiKeys.SEL_PORT];
        }
        return Object.keys(state.portfolios || {}).pop();
    }
    return undefined;
};

export const getPortNextId = createSelector(
    [getPortById],
    (portById) => {
        if (portById && Object.keys(portById).length>0) {
            return 'port' + (Math.max(...(Object.keys(portById).map(i=>parseInt(i.slice(4),10))))+1);
        } else {
            return 'port1';
        }
    }
);

export const getPortLastId = createSelector(
    [getPortById],
    (portById) => {
        let keys = Object.keys(portById);
        return keys.length>0 ? keys[keys.length-1] : 'port1';
    });

export const getPortNames = createSelector(
    [getPortById],
    (portById) => {
        return Object.keys(portById).map(i => ({id: i, name: portById[i].name, readonly: !!portById[i].readonly}));}
);

export const getSelectedPort = createSelector(
    [ getSelectedPortId, getPortById ],
    ( selId, portById ) => {
        let port = (portById && portById[selId] && {...portById[selId]}) || {};
        if (port.weighting && !port.weighting.methods) {
            port.weighting.methods = [{...port.weighting, weight: 1}];
        }
        return port;
    }
);

const getFX = state => (state.params && state.params.fx) || [];

const getFXRates = createSelector(
    [getSelectedPort, getFX],
    (port, fx) => {
        let conv = port.currency || 'USD';
        conv = conv === 'EUR' ? 1 : 1/fx.filter(r => r.toCurr === conv)[0].value;
        return [...fx, {toCurr: 'EUR', value: 1}].reduce((a,e) => ({...a, [e.toCurr]: e.value * conv}), {});
    }
);

export const getClassesByName = createSelector(
    [getSelectedPort],
    (port) => port && port.assetsClasses ?
        port.assetsClasses.reduce((a,e) => ({...a, [e.assetClass]: e.weight}), {}) : {});

export const getAssetsDF = createLoggedSelector( 'assetsDF',
    [getSelectedPort, getContractsDF, getFXRates],
    (port, contracts, fx) => {
        if (port && port.assets && port.assetsClasses && port.assets.data && port.assets.data.length>0){
            let size = port.size || 100000;
            let assets = (new DataFrame(port.assets.data, port.assets.columns)).restructure(assetsColsState);
            let assetsClasses = (new DataFrame(port.assetsClasses.data, port.assetsClasses.columns)).restructure(classesColsState);
            let idx = 0;

            let contractsCount = {};

            assets = assets
                .withColumn('asset_id', () => idx++)
                .leftJoin(contracts.select('genericCode', 'contractName', 'currency', 'type', 
                                            'indicator', 'lastPrices', 'rollContract_selValues'),
                    'genericCode')
                .rename('type', 'assetClass')
                .leftJoin(assetsClasses.select('assetClass', 'classWeight'), 'assetClass')
                .chain(
                    r => r.set('classWeight', (r.get('classWeight') || r.get('classWeight') === 0) ? r.get('classWeight') : (r.get('assetClass') ? 1 : 0)),
                    r => {
                        let id = r.get('assetClass');
                        contractsCount[id] = contractsCount[id] ? contractsCount[id] + 1 : 1;
                        return true;
                    }
                );

            return assets
                .withColumn('weight', row => {
                    return contractsCount[row.get('assetClass')] ?
                        row.get('assetWeight')*row.get('classWeight')/contractsCount[row.get('assetClass')] || 0 : 0;
                })
                .withColumn('lastPrice', row => row.get('lastPrices') && row.get('lastPrices')[row.get('rollContract')] ?
                    row.get('lastPrices')[row.get('rollContract')].settle : 0)
                .withColumn('size', row => row.get('lastPrice')*row.get('multiplier')/fx[row.get('currency')] || 0)
                .withColumn('contracts', row => size * row.get('weight') / row.get('size'))
                .sortBy('asset_id');

        }
        return new DataFrame([], assetsColsTable);
    }
);

export const getAssets = createSelector(
    [getAssetsDF],
    (assets) => {
        return assets ? formatDF(assets.select(...assetsColsTable)) : {};
    });

const getStratsDF = createLoggedSelector( 'stratsDF',
    [getSelectedPort, getAssetsDF],
    (port, assets) => {
        if (port && port.assets && port.assetsClasses && port.strats){
            try {
                let strats = new DataFrame(port.strats.data, port.strats.columns);

                if (port.strats.columns.indexOf('ticker') >= 0) {
                    let idx = 0;
                    strats =  strats
                        .withColumn('idx_fzaefsqfsqdf', row => idx++)
                        .leftJoin(assets.select('ticker','rollContract','assetClass','indicator','weight'),
                            ['ticker','rollContract'])
                        .withColumn('weight', row => (row.get('stratWeight') && row.get('weight')) ?
                            row.get('stratWeight')*row.get('weight') : 0)
                        .map(row => row.set('indicator', 'long;short;' + row.get('indicator')))
                        .rename('indicator', 'strats_selValues')
                        .sortBy('idx_fzaefsqfsqdf')
                        .drop('idx_fzaefsqfsqdf');
                }

                return strats.withColumn('strats_selValues', ()=>'long;short;trend;carry;value');
            } catch (e) {
                logger(e);
            }
        }
        return new DataFrame([], stratsClassesColsTable);
    }
);
export const getStrats = createSelector(
    [getStratsDF],
    strats =>  strats ? formatDF(strats.restructure(strats.listColumns().indexOf('ticker') >= 0 ?
        stratsAssetsColsTable : stratsClassesColsTable)) : {});

const getAssetsClassesDF = createLoggedSelector( 'classesDF',
    [getSelectedPort, getAssetsDF],
    (port, assets) => {
        if (port && port.assets && port.assetsClasses && port.strats && port.assetsClasses.data && port.assetsClasses.data.length>0){
            try {
                let assetsClasses = new DataFrame(port.assetsClasses.data, port.assetsClasses.columns);
                let nbContracts = assets
                    .select('genericCode', 'rollContract', 'assetClass')
                    .groupBy('assetClass')
                    .aggregate(group => group.dropDuplicates().count())
                    .renameAll(['assetClass','nbContracts']);
                return assetsClasses
                    .leftJoin(nbContracts, 'assetClass');
            } catch (e) {
                logger(e);
            }
        }
        return new DataFrame([], classesColsTable);
    }
);
export const getAssetsClasses = createSelector(
    [getAssetsClassesDF],
    classes => classes ? formatDF(classes.select(...classesColsTable)):{});


// selector for the tree
const getBacktests = state => state.data.backtest;
export const getBacktestsTree = createSelector(
    [getBacktests],
    (bckt) => {
        let res = [];
        for (let i in bckt) {
            let pos = [];
            for (let j in bckt[i].positions) {
                pos.push({
                    id: j, name: j,
                    subseries: ['assetWeight', 'position', 'signal'].map(e=>({id:e,name:e}))
                });
            }
            res.push({
                id: i, name: bckt[i].name,
                subseries: [
                    {id: 'positions', name: 'Positions', subseries:pos}
                ]
            });
        }
        return res;
    }
);
