import {createSelector} from 'reselect';


export const logger = process.env.REACT_APP_STAGE === 'dev' ? 
    (...args) => {
        console.log(...args, '(timer: ', ((new Date()).getTime() % (1000*300))/1000, ')');
        return args[0];
    } : 
    (...args) => {};
    
export const createLoggedSelector = (name, inputs, fun) => createSelector(
    inputs,
    (...args) => {
        logger('Selector', name, 'start');
        let res = fun(...args);
        logger('Selector', name, 'end');
        return res;
    }
);
