import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from 'actions/actionTypes';
import {
  contactSendSuccess, contactSendError,
  contactSubscribeSuccess, contactSubscribeError
} from 'actions/contactActions';

import {sendMessage, subscribeEmail} from 'api/mailer';

function* contactSend(action) {
  const {err} = yield call(sendMessage, action.payload);
  if (err) {
    yield put(contactSendError(err));
  } else {
    yield put(contactSendSuccess());
  }
}

export function* handleContactSend() {
  yield takeLatest(types.CONTACT_SEND, contactSend);
}

function* contactSubscribe(action) {
  const {err} = yield call(subscribeEmail, action.payload);
  if (err) {
    yield put(contactSubscribeError(err));
  } else {
    yield put(contactSubscribeSuccess());
  }
}

export function* handleContactSubscribe() {
  yield takeLatest(types.CONTACT_SUBSCRIBE, contactSubscribe);
}
